import React, { useContext } from 'react';
import styled from 'styled-components';
import { FaInstagram } from 'react-icons/fa';

import { Box, Text, Space } from 'components';
import { ListInstagram } from './ListInstagram';

import { ContextLanguage } from 'contexts';

const Container = styled(Box)`
  width: 100%;
  padding: 32px;
`;

const IconInstagram = styled(FaInstagram)`
  font-size: 22px;
`;

export const Instagram = () => {
  const cxtLang = useContext(ContextLanguage);

  return (
    <Container>
      <Text size="22" color="black" type="h2" bold>
        <IconInstagram /> INSTAGRAM
      </Text>

      <Space mt={1} />
      <Text size="20" type="h3" color="black" semiBold>
        {cxtLang.lang === 'pt' && 'Hello+ COMPARTILHE BONS MOMENTOS'}
        {cxtLang.lang === 'en' && 'Hello+ Share your photo'}
        {cxtLang.lang === 'es' && 'Hello+ COMPARTE BUENOS MOMENTOS'}
      </Text>

      <Space mt={1} />

      <Text size="18" color="black" semiBold>
        {cxtLang.lang === 'pt' && 'Poste sua foto utilizando '}
        {cxtLang.lang === 'en' && 'Use the '}
        {cxtLang.lang === 'es' && '¡Postea tu foto usando '}
        <Text size="18" color="secondary" bold>
        #HELLOMAIS
        </Text>{' '}
        {cxtLang.lang === 'pt' && 'no Instagram para ver sua foto aqui no nosso mural'}
        {cxtLang.lang === 'en' && 'on Instagram to see your photo here on our wall'}
        {cxtLang.lang === 'es' && 'en Instagram para ver tu foto aquí en nuestro muro'}
      </Text>
      <br />

      <ListInstagram />
    </Container>
  );
};
