import styled from 'styled-components';
import { Loader } from '@ederzadravec/jade-ui';

import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
`;

const ListaImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex: 1;
  flex-direction: row;
  max-height: 580px;
  overflow: auto;
`;

const ContainerImage = styled.div`
  display: flex;
  width: calc((100% / 4) - 16px);
  margin-right: 16px;
  margin-bottom: 16px;

  @media (max-width: 1367px) {
    width: calc((100% / 2) - 16px);
  }
`;

const Image = styled.img`
  display: flex;
  width: 100%;
  border-radius: 4px;
`;

const ImageInsta = ({ src }) => {
  return (
    <ContainerImage>
      <Image src={src} />
    </ContainerImage>
  );
};

export const ListInstagram = () => {
  const [dataInstagram, { loading }] = useService(
    'get',
    `https://api.hellomais.com.br/api/enterprise/instagram/all/public?perPage=30`
  );

  const data = dataInstagram?.data || [];

  return (
    <Container>
      <Loader show={loading}>
        <ListaImages>
          {data.map(post => (
            <ImageInsta key={post.id} src={`https://api.hellomais.com.br/${post.cacheFilepath}`} />
          ))}
        </ListaImages>
      </Loader>
    </Container>
  );
};
