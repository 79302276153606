import styled from 'styled-components';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.white.main};
  border-radius: 14px;
  margin-bottom: 24px;
  padding: 24px;
`;

export const Box = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};
