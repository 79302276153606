import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { hooks, FormGrid, TextInput, TextArea, Loader } from '@ederzadravec/jade-ui';

import { Text, Space, Modal, Button } from 'components';
import { useService } from 'service';

const validations = {
  namePt: [[value => !value, 'Campo obrigatório']],
  descriptionPt: [[value => !value, 'Campo obrigatório']],
};

const qtde = 4;

export const FormPoll = ({ onClose, id_room, BASE_URL }) => {
  let { poll: id_poll } = useParams();
  const history = useHistory();

  const [, { loading: pollLoading }, fetchPoll] = useService(
    'get',
    `/room/${id_room}/poll/${id_poll}`,
    {},
    false
  );
  const [, { loading }, savePoll] = useService('post', `/room/${id_room}/poll`, {}, false);
  const [, { loading: updateLoading }, updatePoll] = useService(
    'patch',
    `/room/${id_room}/poll/${id_poll}`,
    {},
    false
  );

  const [form, onChange] = hooks.useForm({
    validations,
    intialValues: { label1: false, label2: false },
  });

  React.useEffect(() => {
    if (!id_poll) {
      return;
    }

    fetchPoll()
      .then(data => {
        if (data?.error) {
          console.log('euu', 'deu ruim');
        }

        if (data?.success?.status === 'OK') {
          const poll = data?.success?.poll;

          const alternatives = Object.keys([...new Array(qtde)]).reduce(
            (acc, key) => ({
              ...acc,
              [`labelPt${key}`]: poll?.alternatives[`${key}`]?.label.pt || '',
              [`labelEn${key}`]: poll?.alternatives[`${key}`]?.label.en || '',
              [`labelEs${key}`]: poll?.alternatives[`${key}`]?.label.es || '',
            }),
            {}
          );

          form.setValues({
            namePt: poll?.name?.pt,
            nameEn: poll?.name?.en,
            nameEs: poll?.name?.es,

            descriptionPt: poll?.description.pt,
            descriptionEn: poll?.description.en,
            descriptionEs: poll?.description.es,
            ...alternatives,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleOnSavePoll = async () => {
    const alternatives = [...new Array(qtde)].map((x, key) => ({
      label:{
        pt: form.getValue(`labelPt${key}`),
        en: form.getValue(`labelEn${key}`),
        es: form.getValue(`labelEs${key}`),
      },
    }));

    const data = {
      _id: id_poll,
      namePt: form.getValue('namePt'),
      nameEn: form.getValue('nameEn'),
      nameEs: form.getValue('nameEs'),
      descriptionPt: form.getValue('descriptionPt'),
      descriptionEn: form.getValue('descriptionEn'),
      descriptionEs: form.getValue('descriptionEs'),
      alternatives,
    };

    const result = id_poll ? await updatePoll(data) : await savePoll(data);

    if (result?.success?.status === 'OK') {
      return onClose(true);
    }

    if (result.error.status === 'FORM') {
      return form.setError(result.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'namePt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'descriptionPt',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Descrição Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'descriptionEn',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Descrição En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'descriptionEs',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Descrição Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'Space',
        size: { md: 12, xs: 12 },
        type: () => (
          <>
            <Text type="h2" size="22" color="primary" bold>
              Opções
            </Text>
            <Space mt={5} />
          </>
        ),
      },
    ],

    ...[...new Array(qtde)].map((x, key) => [
      {
        schema: `labelPt${key}`,
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: `Alternativa Pt ${key + 1}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: `labelEn${key}`,
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: `Alternativa En ${key + 1}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: `labelEs${key}`,
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: `Alternativa Es ${key + 1}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ]),
  ];

  return (
    <Modal
      size={{ md: 6 }}
      onClose={() => {
        history.push(BASE_URL);
        onClose(false);
      }}
      title="Formulário de Votação">
      <Loader show={pollLoading}>
        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSavePoll)}>
          <Space mt={5} />

          <Button loading={loading || updateLoading}>Salvar</Button>
        </FormGrid>
      </Loader>
    </Modal>
  );
};
