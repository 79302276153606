import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  hooks,
  FormGrid,
  TextInput,
  TextArea,
  Switch,
  Button,
  Loader,
} from '@ederzadravec/jade-ui';

import { Text, Space, Modal } from 'components';
import { useService } from 'service';

const validations = {
  namePt: [[value => !value, 'Campo obrigatório']],
  descriptionPt: [[value => !value, 'Campo obrigatório']],
};

const qtde = 4;

export const FormQuiz = ({ onClose, BASE_URL, id_room }) => {
  let { quiz: id_quiz } = useParams();
  const history = useHistory();

  const [, { loading: quizLoading }, fetchQuiz] = useService(
    'get',
    `/room/${id_room}/quiz/${id_quiz}`,
    {},
    false
  );

  const [, { loading }, saveQuiz] = useService('post', `/room/${id_room}/quiz`, {}, false);
  const [, { loading: updateLoading }, updateQuiz] = useService(
    'patch',
    `/room/${id_room}/quiz/${id_quiz}`,
    {},
    false
  );

  const [form, onChange] = hooks.useForm({
    validations,
    intialValues: { correct1: false, correct2: false, correct3: false, correct4: false },
  });

  React.useEffect(() => {
    if (!id_quiz) return;

    fetchQuiz()
      .then(data => {
        if (data?.error) {
          console.log('euu', 'deu ruim');
        }

        if (data?.success?.status === 'OK') {
          const quiz = data?.success?.quiz;

          const alternatives = Object.keys([...new Array(qtde)]).reduce(
            (acc, key) => ({
              ...acc,
              [`labelPt${key}`]: quiz?.alternatives[`${key}`]?.label.pt || '',
              [`labelEn${key}`]: quiz?.alternatives[`${key}`]?.label.en || '',
              [`labelEs${key}`]: quiz?.alternatives[`${key}`]?.label.es || '',
              [`isCorrect${key}`]: quiz?.alternatives[`${key}`]?.isCorrect || '',
            }),
            {}
          );

          form.setValues({
            namePt: quiz?.name?.pt,
            nameEn: quiz?.name?.en,
            nameEs: quiz?.name?.es,

            descriptionPt: quiz?.description.pt,
            descriptionEn: quiz?.description.en,
            descriptionEs: quiz?.description.es,

            ...alternatives,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleOnSave = async () => {
    const alternatives = [...new Array(qtde)].map((x, key) => ({
      label: {
        pt: form.getValue(`labelPt${key}`),
        en: form.getValue(`labelEn${key}`),
        es: form.getValue(`labelEs${key}`),
      },
      isCorrect: form.getValue(`isCorrect${key}`) || false,
    }));

    const data = {
      _id: id_quiz,
      namePt: form.getValue('namePt'),
      nameEn: form.getValue('nameEn'),
      nameEs: form.getValue('nameEs'),
      descriptionPt: form.getValue('descriptionPt'),
      descriptionEn: form.getValue('descriptionEn'),
      descriptionEs: form.getValue('descriptionEs'),
      alternatives,
    };

    const result = id_quiz ? await updateQuiz(data) : await saveQuiz(data);

    if (result?.success?.status === 'OK') {
      return onClose(true);
    }

    if (result.error.status === 'FORM') {
      return form.setError(result.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'namePt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'descriptionPt',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Pergunta Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'descriptionEn',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Pergunta En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'descriptionEs',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Pergunta Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'Space',
        size: { md: 12, xs: 12 },
        type: () => (
          <>
            <Text type="h2" size="22" color="primary" bold>
              Opções
            </Text>
            <Space mt={2} />
          </>
        ),
      },
    ],

    ...[...new Array(qtde)].map((x, key) => [
      {
        schema: `labelPt${key}`,
        size: { md: 3 },
        type: TextInput,
        props: schema => ({
          label: `Alternativa Pt ${key + 1}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: `labelEn${key}`,
        size: { md: 3 },
        type: TextInput,
        props: schema => ({
          label: `Alternativa En ${key + 1}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: `labelEs${key}`,
        size: { md: 3 },
        type: TextInput,
        props: schema => ({
          label: `Alternativa Es ${key + 1}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: `isCorrect${key}`,
        size: { md: 3 },
        type: Switch,
        props: schema => ({
          label: 'Correta?',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ]),
  ];

  return (
    <Modal
      size={{ md: 6 }}
      onClose={() => {
        onClose(false);
      }}
      title="Formulário de Quiz">
      <Loader loading={quizLoading}>
        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSave)}>
          <Space mt={5} />

          <Button loading={loading || updateLoading}>Salvar</Button>
        </FormGrid>
      </Loader>
    </Modal>
  );
};
