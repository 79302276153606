import React from 'react';
import styled, { css } from 'styled-components';

const defaults = css`
  font-weight: 500;
  ${({ bold }) => bold && 'font-weight: bold;'};
  ${({ center }) => center && 'text-align: center; width: 100%;'};
  ${({ end }) => end && 'text-align: end; width: 100%;'};
  ${({ semiBold }) => semiBold && 'font-weight: 600;'};
  ${({ link }) => link && 'text-decoration: underline; cursor: pointer;'};
  color: ${({ theme, color, variant }) => (color ? variant ? theme.palette[color][variant] : theme.palette[color].main : '')};
`;

const H1 = styled.h1`
  font-size: ${({ size }) => (size ? size : '34')}px;
  ${defaults}
`;

const H2 = styled.h2`
  font-size: ${({ size }) => (size ? size : '28')}px;
  ${defaults}
`;

const H3 = styled.h3`
  font-size: ${({ size }) => (size ? size : '22')}px;
  ${defaults}
`;

const Span = styled.span`
  font-size: ${({ size }) => (size ? size : '12')}px;
  ${defaults}
`;

const Link = styled.span`
  font-size: ${({ size }) => (size ? size : '12')}px;
  ${defaults}

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const P = styled.p`
  font-size: ${({ size }) => (size ? size : '12')}px;
  ${defaults}
`;

export const Text = ({ children, type, ...props }) => {
  return (
    <>
      {type === 'p' && <P {...props}>{children}</P>}

      {type === 'span' && <Span {...props}>{children}</Span>}

      {type === 'link' && <Link {...props}>{children}</Link>}

      {type === 'h1' && <H1 {...props}>{children}</H1>}

      {type === 'h2' && <H2 {...props}>{children}</H2>}

      {type === 'h3' && <H3 {...props}>{children}</H3>}
    </>
  );
};

Text.defaultProps = {
  type: 'span',
  color: '',
  variant: '',
};
