import React from 'react';
import styled from 'styled-components';
import { hooks, FormGrid, Button, TextInput, TextArea, Space, Loader } from '@ederzadravec/jade-ui';

import { Text } from 'components';
import { useService } from 'service';
import { isNotValidDate, dateToDb, dbToDate } from 'utils/date';

const Container = styled.div`
  width: 100%;
  padding-top: 24px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const validations = {
  openAt: [
    [value => !value, 'Deve ser preenchido'],
    [value => isNotValidDate(value, 'dd/MM/yyyy HH:mm'), 'Deve ser uma data válida'],
  ],
  closeAt: [
    [value => !value, 'Deve ser preenchido'],
    [value => isNotValidDate(value, 'dd/MM/yyyy HH:mm'), 'Deve ser uma data válida'],
  ],
  namePt: [[value => !value, 'Deve ser preenchido']],
  titlePt: [[value => !value, 'Deve ser preenchido']],
  descriptionPt: [[value => !value, 'Deve ser preenchido']],
};

export const Form = ({ match, history, onClose }) => {
  const BASE_URL = '/admin/room';
  const id_room = match.params.room;

  const [, { loading: roomLoaging }, fetchRoom] = useService('get', `/room/${id_room}`, {}, false);
  const [, { loading }, saveRoom] = useService('post', '/room', {}, false);
  const [, { loading: updateLoading }, updateRoom] = useService(
    'patch',
    `/room/${id_room}`,
    {},
    false
  );

  const [form, onChange] = hooks.useForm({ validations });

  id_room &&
    React.useEffect(() => {
      if (!id_room) {
        return;
      }

      fetchRoom().then(data => {
        if (data?.error) {
          console.log('euu', 'deu ruim');
        }

        if (data?.success?.status === 'OK') {
          const room = data?.success?.room;

          console.log("eee", room)

          form.setValues({
            ...room,
            namePt: room.name.pt,
            nameEn: room.name.en,
            nameEs: room.name.es,

            titlePt: room.title.pt,
            titleEn: room.title.en,
            titleEs: room.title.es,

            resumeDescriptionPt: room.resumeDescription.pt,
            resumeDescriptionEn: room.resumeDescription.en,
            resumeDescriptionEs: room.resumeDescription.es,

            descriptionPt: room.description.pt,
            descriptionEn: room.description.en,
            descriptionEs: room.description.es,

            linkPt: room.link.pt,
            linkEn: room.link.en,
            linkEs: room.link.es,

            linkEvaluationPt: room.linkEvaluation.pt,
            linkEvaluationEn: room.linkEvaluation.en,
            linkEvaluationEs: room.linkEvaluation.es,
            
            openAt: dbToDate(room?.openAt, 'dd/MM/yyyy HH:mm'),
            closeAt: dbToDate(room?.closeAt, 'dd/MM/yyyy HH:mm'),
          });
        }
      });
    }, []);

  const handleOnSubmit = async () => {
    const data = {
      openAt: dateToDb(form.getValue('openAt'), 'dd/MM/yyyy HH:mm'),
      closeAt: dateToDb(form.getValue('closeAt'), 'dd/MM/yyyy HH:mm'),
      namePt: form.getValue('namePt'),
      nameEn: form.getValue('nameEn'),
      nameEs: form.getValue('nameEs'),
      titlePt: form.getValue('titlePt'),
      titleEn: form.getValue('titleEn'),
      titleEs: form.getValue('titleEs'),
      resumeDescriptionPt: form.getValue('resumeDescriptionPt'),
      resumeDescriptionEn: form.getValue('resumeDescriptionEn'),
      resumeDescriptionEs: form.getValue('resumeDescriptionEs'),
      descriptionPt: form.getValue('descriptionPt'),
      descriptionEn: form.getValue('descriptionEn'),
      descriptionEs: form.getValue('descriptionEs'),
      linkPt: form.getValue('linkPt'),
      linkEn: form.getValue('linkEn'),
      linkES: form.getValue('linkEs'),
      linkEvaluationPt: form.getValue('linkEvaluationPt'),
      linkEvaluationEn: form.getValue('linkEvaluationEn'),
      linkEvaluationEs: form.getValue('linkEvaluationEs'),
    };

    const result = id_room ? await updateRoom(data) : await saveRoom(data);

    if (result?.success?.status === 'OK') {
      return history.push(`${BASE_URL}`);
    }

    if (result?.error?.status === 'FORM') {
      return form.setError(result.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'openAt',
        size: { md: 6 },
        type: TextInput,
        props: schema => ({
          label: 'Abertura',
          variant: 'timedate',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'closeAt',
        size: { md: 6 },
        type: TextInput,
        props: schema => ({
          label: 'Fechamento',
          variant: 'timedate',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'namePt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome - Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome - En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'nameEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Nome - Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'titlePt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo - Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'titleEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo - En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'titleEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Título - Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'resumeDescriptionPt',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Resumo - Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'resumeDescriptionEn',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Resume - En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'resumeDescriptionEs',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Resume - Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'descriptionPt',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Descrição - PT',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'descriptionEn',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Descrição - En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'descriptionEs',
        size: { md: 4 },
        type: TextArea,
        props: schema => ({
          label: 'Descrição - Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'linkPt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Link - Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'linkEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Link - En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'linkEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Link - Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'linkEvaluationPt',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Link Avaliação - Pt',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'linkEvaluationEn',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Link Avaliação - En',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'linkEvaluationEs',
        size: { md: 4 },
        type: TextInput,
        props: schema => ({
          label: 'Link Avaliação - Es',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Header>
        <Text type="h3" size="24" bold color="black">
          Criar/Editar Salas
        </Text>

        <Button
          onClick={() => {
            history.push(`${BASE_URL}`);
          }}>
          Voltar
        </Button>
      </Header>

      <Space top={4} />
      <hr />
      <Space top={4} />

      <Loader loading={roomLoaging}>
        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
          <Space top={3}>
            <Button loading={loading || updateLoading}>Enviar</Button>
          </Space>
        </FormGrid>
      </Loader>
    </Container>
  );
};
