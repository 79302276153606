import styled from 'styled-components';
import { Grid, Loader } from '@ederzadravec/jade-ui';
import { useLocation } from 'react-router-dom';

import { Box as CPBox, Text, Space } from 'components';
import { useService } from 'service';
import { getQuery } from 'utils/url';

const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const Box = styled(CPBox)`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Display = ({ label, value, loading }) => {
  return (
    <Grid size={{ md: 3 }}>
      <Loader show={loading} direction="column">
        <Box>
          <Text size="18" color="black" bold>
            {label}
          </Text>

          <Space mt={2} />

          <Text size="50" color="black">
            {value}
          </Text>
        </Box>
      </Loader>
    </Grid>
  );
};

export const Summary = () => {
  const location = useLocation();
  const query = getQuery(location.search);

  const [viewersWatching, { loading: loadingViewersWatching }] = useService(
    'get',
    '/dashboard/viewers-watching',
    { room: query.room },
    true,
    [query.room]
  );

  const [viewersSchedule, { loading: loadingViewersSchedule }] = useService(
    'get',
    '/dashboard/viewers-scheduled',
    { room: query.room },
    true,
    [query.room]
  );

  const [viewersEntryRoom, { loading: loadingViewersEntryRoom }] = useService(
    'get',
    '/dashboard/viewers-entry-room',
    { room: query.room },
    true,
    [query.room]
  );

  const [viewersAverageTime, { loading: loadingViewersAverageTime }] = useService(
    'get',
    '/dashboard/viewers-average-time',
    { room: query.room },
    true,
    [query.room]
  );

  const averageTime = viewersAverageTime?.success?.viewersAverageTime || {
    hours: '00',
    minutes: '00',
    seconds: '00',
  };

  return (
    <Container>
      <Grid container>
        <Display
          label="Assistindo Agora"
          value={viewersWatching?.success?.viewersWatching || 0}
          loading={loadingViewersWatching}
        />

        <Display
          label="Plays Totais"
          value={viewersEntryRoom?.success?.viewersEntryRoom || 0}
          loading={loadingViewersEntryRoom}
        />

        <Display
          label="Total de Inscritos"
          value={viewersSchedule?.success?.viewersSchedule || 0}
          loading={loadingViewersSchedule}
        />

        <Display
          label="Tempo Médio de Exibição"
          value={`${averageTime.hours}:${averageTime.minutes}:${averageTime.seconds}`}
          loading={loadingViewersAverageTime}
        />
      </Grid>
      <br />
    </Container>
  );
};
