import React from 'react';
import styled from 'styled-components';
import { Button } from '@ederzadravec/jade-ui';
import { MdClose } from 'react-icons/md';
import { FaRegCheckCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { Text, Space, Toastr } from 'components';
import { dbToDate } from 'utils/date';
import { useService } from 'service';

const Container = styled.div`
  background: #fff;
  position: absolute;
  width: inherit;
  min-height: 500px;
  z-index: 1;
  padding: 24px;
  border-radius: 16px;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    flex-wrap: wrap;
    min-height: 600px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Body = styled.div`
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

const ContentImage = styled.div`
  margin-right: 16px;
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0 16px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 16px;
  }
`;

const ContentInfo = styled.div`
  margin-left: 16px;
  width: calc(100% - 250px);

  @media (max-width: 768px) {
    margin-top: 16px;
    width: 100%;
  }
`;

const DescriptionFull = styled.div`
  max-height: 260px;
  overflow: auto;
  font-size: 16px;

  @media (max-width: 768px) {
    max-height: 160px;
  }
`;

const IconClose = styled(MdClose)`
  font-size: 30px;
  cursor: pointer;
`;

const Checked = styled(FaRegCheckCircle)`
  font-size: 20px;
`;

const Title = styled(Text)`
  font-weight: 800;
  line-height: 40px;
  width: 100%;
`;

const ButtonClosed = styled(Button)`
  background: ${({ theme }) => theme.palette.black.light};
  color: ${({ theme }) => theme.palette.black.main};
  cursor: not-allowed;
  border: 0;

  :hover {
    cursor: not-allowed;
  }
`;

export const CardDetail = ({ data, setShowDetail, schedule, setSchedule }) => {
  const [, { loading }, addToSchedule] = useService('post', `/app/schedule/${data._id}`, {}, false);
  const history = useHistory();
  const inShedule = data.scheduled;

  const handleOnSchedule = async data => {
    const result = await addToSchedule();

    if (result.success.status === 'CONTAIN') {
      Swal.fire({
        title: 'Você já possui uma palestra para esse horário!',
        confirmButtonText: 'Ok',
      });

      return;
    }

    Toastr.open({
      title: data.title + ' | Foi adicionado à sua agenda',
      labelButtom: 'Ok',
      time: 8000,
    });

    setSchedule(data._id);
    const link = `
    https://calendar.google.com/calendar/u/0/r/eventedit?text=${data.name}
    &dates=${dbToDate(data.openAt, "yyyyMMdd'T'HHmmss")}/${dbToDate(
      data.closeAt,
      "yyyyMMdd'T'HHmmss"
    )}
    &ctz=America/Sao_Paulo
    &details=${data.resumeDescription}
    &location=https://app.hellomais.com.br
    &pli=1
    &uid=
    &sf=true
    &output=xml
    `;

    const win = window.open(link, '', 'width=800,height=600');
    win.focus();
  };

  return (
    <Container>
      <Header>
        <Text></Text>

        <IconClose onClick={() => setShowDetail(false)} />
      </Header>

      <Body>
        <ContentImage>
          <Title type="h2" size="22" color="white" center bold>
            {data.name}
          </Title>
        </ContentImage>

        <ContentInfo>
          <Text size="24" type="h1" color="primary" bold>
            {data.title}
          </Text>

          <Space mt={1} />

          <Text size="16" color="primary" bold>
            {data?.talks[0]?.speaker?.name}
          </Text>

          <Text size="16" type="h3" color="primary">
            {dbToDate(data?.openAt, " dd 'de' MMMM")} - {dbToDate(data?.openAt, " HH'h'mm")}{' '}
            <Text size="16" color="primary" semiBold>
              às
            </Text>
            {dbToDate(data?.closeAt, " HH'h'mm")}
          </Text>
          <Space mt={2} />

          <DescriptionFull dangerouslySetInnerHTML={{ __html: data.description }} />

          <Space mt={2} />

          {data.isOnline && !data.isClosed && (
            <Button color="primary" onClick={() => history.push(`/app/room/${data._id}`)}>
              ASSISTIR
            </Button>
          )}

          {!data.isOnline && data.isClosed && (
            <ButtonClosed onClick={() => history.push(`/app/room/${data._id}`)}>
              Encerrado
            </ButtonClosed>
          )}

          {!data.isOnline && !data.isClosed && inShedule && !schedule && (
            <Button color="primary" iconAfter={Checked}>
              AGENDADO
            </Button>
          )}

          {!data.isOnline && !data.isClosed && !inShedule && data._id === schedule && (
            <Button color="primary" iconAfter={Checked}>
              AGENDADO
            </Button>
          )}

          {!data.isOnline && !data.isClosed && !inShedule && data._id !== schedule && (
            <Button
              color="primary"
              loading={loading}
              variant="outline"
              onClick={() => handleOnSchedule(data)}>
              SALVAR NA AGENDA
            </Button>
          )}
          <br />
        </ContentInfo>
      </Body>
    </Container>
  );
};
