import React, { useContext } from 'react';
import styled from 'styled-components';
import { Grid } from '@ederzadravec/jade-ui';

import { Text, Space } from 'components';
import LogoBrand from 'assets/images/brand.svg';
import BGPage from 'assets/images/bg_login.svg';
import ImagemLeft from 'assets/images/image_left.svg';

import { ContextLanguage } from 'contexts';

import { FormConfirm } from './FormConfirm';
const Container = styled.div`
  width: 100%;
  background-image: url(${BGPage});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 24px;

  @media (max-width: 768px) {
    background-position: center;
  }
`;

const Content = styled.div`
  width: 1140px;
  max-width: 100%;
  margin: auto;
  border-radius: 14px;
  padding: 24px;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const ContentForm = styled.div`
  width: 350px;
  min-height: 378px;
  margin: auto;
  background: #fff;
  border-radius: 16px;
  padding: 24px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    padding: 16px;
    width: 90%;
  }
`;

const ImageLogo = styled.img`
  display: block;
  width: 80%;
  margin: auto;
  padding: 20px;
`;

const Image = styled.img`
  width: 348px;
  height: 348px;
  display: block;
  margin: 16px auto 16px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    margin-bottom: 30px;
    max-width: 150px;
  }
`;

const Footer = styled(Text)`
  color: #bbbbbb;
  bottom: 10px;
  display: none;
`;

const SetLanguage = ({ setLang }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
        display: 'flex',
        background: 'white',
      }}>
      <span
        style={{ padding: '8px', cursor: 'pointer', margin: '5px' }}
        onClick={() => setLang('es')}>
        ES
      </span>
      <span
        style={{ padding: '8px', cursor: 'pointer', margin: '5px' }}
        onClick={() => setLang('en')}>
        EN
      </span>
      <span
        style={{ padding: '8px', cursor: 'pointer', margin: '5px' }}
        onClick={() => setLang('pt')}>
        PT
      </span>
    </div>
  );
};

export default () => {
  const cxtLang = useContext(ContextLanguage);

  return (
    <Container>
      <SetLanguage setLang={cxtLang.setLang} />
      <Content>
        <Grid container>
          <Grid size={{ md: 1, xs: 12 }}></Grid>
          <Grid size={{ md: 4, xs: 12 }}>
            <Image src={ImagemLeft} alt="Logo" />
          </Grid>
          <Grid size={{ md: 2, xs: 12 }}></Grid>

          <Grid size={{ md: 4, xs: 12 }}>
            <ContentForm>
              <ImageLogo src={LogoBrand} alt="Logo" />

              <Space mt={2} />

              <FormConfirm />
            </ContentForm>
          </Grid>
          <Grid size={{ md: 1, xs: 12 }}></Grid>
        </Grid>
      </Content>

      <Footer center bold>
        Desenvolvido por BTO+ Comunicação e Deploy. Digital Business
      </Footer>
    </Container>
  );
};
