import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { hooks, FormGrid, TextInput, Button, Loader } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { Text, Space } from 'components';
import { useService } from 'service';

const Container = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const ContentButton = styled.div`
  width: 250px;
  display: inline-block;
  margin-right: 12px;
`;

const validations = {};

const NewPass = () => {
  const { id: idUser } = useParams();

  const [, { loading: newPassLoading }, newPass] = useService(
    'patch',
    `user/${idUser}/newpass`,
    null,
    false
  );

  const HandleNewPassword = async () => {
    const result = await newPass();

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: 'Uma nova senha foi enviada para o email de cadastro do Usuário',
      });
    }

    if (result?.error) {
      return form.setError(result?.error);
    }
  };

  return (
    <ContentButton>
      <Button type="button" loading={newPassLoading} onClick={() => HandleNewPassword()}>
        Gerar Nova Senha
      </Button>
    </ContentButton>
  );
};

export const Form = ({ userData }) => {
  const [, { loading: updateLoading }, update] = useService('patch', `user/`, null, false);

  const [form, onChange] = hooks.useForm({ validations });

  React.useEffect(() => {
    if (!userData?.success) {
      return;
    }

    form.setValues(userData?.success?.user);
  }, [userData]);

  const handleOnSubmit = async () => {
    const data = {
      id: form.getValue('_id'),
      name: form.getValue('name'),
      email: form.getValue('email'),
      cpf: form.getValue('cpf'),
    };

    const result = await update(data);

    if (result?.error) {
      return form.setError(result?.error);
    }

    if (result?.success?.status === 'OK') {
    }
  };

  const formConfig = [
    [
      {
        schema: 'name',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'email',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'cpf',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'CPF',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h2" size="18" color="primary" bold>
        Dados Pessoais
      </Text>

      <Space mt={2} />

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space mt={2} />

        <NewPass />

        <ContentButton>
          <Button loading={updateLoading}>Salvar</Button>
        </ContentButton>
      </FormGrid>
    </Container>
  );
};
