import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';

import { ContextLanguage } from 'contexts';

import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input`
  padding: 10px 8px;
  border-radius: 20px;
  width: 300px;
  font-size: 18px;

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Result = styled.div`
  display: ${({ visible }) => (visible ? `block` : `none`)};

  position: absolute;
  top: 50px;
  background-color: #fff;
  width: 100%;
  padding: 4px 16px;
  max-height: 200px;
  overflow: auto;
  box-sizing: border-box;
  z-index: 5;
  border-radius: 4px;
`;

const ItemResult = styled.h3`
  margin-bottom: 4px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    background: #dedede;
  }
`;

export const Search = () => {
  const [input, setInput] = React.useState('');
  const [showResult, setShowResult] = React.useState(false);

  const history = useHistory();

  const cxtLang = useContext(ContextLanguage);

  const [dataSearch, {}] = useService('get', `/app/room/rooms-search?limit=5000`, {}, true);

  let data = dataSearch?.success?.rooms?.data || [];

  const handleOnChange = e => {
    e.preventDefault();

    e.target.value ? setShowResult(true) : setShowResult(false);

    setInput(e.target.value);
  };

  if (data?.length > 0) {
    data = data.filter(i => {
      var name = R.toLower(i.name[cxtLang.lang]);
      return name.match(R.toLower(input));
    });
  }

  const handleOnPush = id => {
    history.push(`/app/result/room/${id}`);

    setShowResult(false);

    setInput('');
  };

  return (
    <Container>
      <Input
        type="text"
        placeholder={cxtLang.texts[cxtLang.lang].navBarTextSearch}
        onChange={handleOnChange}
        value={input}
      />
      <Result visible={showResult}>
        {data.map((item, key) => (
          <ItemResult
            key={key}
            type="h3"
            size="18"
            color="black"
            onClick={() => {
              handleOnPush(item._id);
            }}>
            {item.name[cxtLang.lang]}
          </ItemResult>
        ))}
      </Result>
    </Container>
  );
};
