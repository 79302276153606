import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button as JDButton, Space } from '@ederzadravec/jade-ui';

import { dbToDate } from 'utils/date';

import { Text } from 'components';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  background: ${({ theme }) => theme.palette.white.main};
  border-bottom: 1px solid ${({ theme }) => theme.palette.black.light};

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding-bottom: 16px;
  }
`;

const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  line-height: 18px;

  @media (max-width: 768px) {
    margin-top: 16px;
    padding: 0 0;

    & > h1 {
      font-size: 16px;
    }
  }
`;

const ContentActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 24px;
  line-height: 18px;

  @media (max-width: 768px) {
    padding: 4px;
  }
`;

const Button = styled(JDButton)``;

export const CardRoom = ({ data, setIdModalShow }) => {
  const cxtLang = useContext(ContextLanguage);
  const history = useHistory();

  const handleToAgenda = () => {
    const link = `
    https://calendar.google.com/calendar/u/0/r/eventedit?text=${data.name[cxtLang.lang]}
    &dates=${dbToDate(data.openAt, "yyyyMMdd'T'HHmmss")}/${dbToDate(
      data.closeAt,
      "yyyyMMdd'T'HHmmss"
    )}
    &ctz=America/Sao_Paulo
    &details=${data.resumeDescription[cxtLang.lang]}
    &location=https://convencao2021.totvs.com
    &pli=1
    &uid=
    &sf=true
    &output=xml
    `;

    const win = window.open(link, '', 'width=800,height=600');
    win.focus();
  };

  return (
    <Container>
      <ContentTitle>
        <Text type="h1" size="22" color="black" bold>
          {data.name[cxtLang.lang]}
        </Text>

        <Space top={1} />

        <Text type="h3" size="18" color="primary">
          {data.title[cxtLang.lang]}
        </Text>
      </ContentTitle>

      <ContentActions>
        {/* <Button color="black" onClick={() => handleToAgenda()}>
          {cxtLang.lang === 'pt' && 'Salvar na agenda'}
          {cxtLang.lang === 'en' && 'Save to calendar'}
          {cxtLang.lang === 'es' && 'Guardar en el calendario'}
        </Button> */}
        {/* <Button color="secondary" onClick={() => setIdModalShow(data)}>
          {cxtLang.lang === 'pt' && 'Ver programação'}
          {cxtLang.lang === 'en' && 'See schedule'}
          {cxtLang.lang === 'es' && 'Ver horario'}
        </Button> */}

        <Space left={2} />

        {data.isClosed === true && data.isOnline === false && (
          <Button
            disabled
            color="primary"
            style={{ maxWidth: '152px' }}
            onClick={() => history.push(`/app/room/${data._id}`)}>
            {cxtLang.lang === 'pt' && 'Encerrado'}
            {cxtLang.lang === 'en' && 'Closed'}
            {cxtLang.lang === 'es' && 'Cerrado'}
          </Button>
        )}

        {data.isOnline === false && data.isClosed === undefined && (
          <Button style={{ maxWidth: '152px' }} color="primary">
            {cxtLang.lang === 'pt' && 'Em breve'}
            {cxtLang.lang === 'en' && 'Coming soon'}
            {cxtLang.lang === 'es' && 'En breve'}
          </Button>
        )}

        {data.isOnline === true && (
          <Button
            style={{ maxWidth: '152px' }}
            color="primary"
            onClick={() => history.push(`/app/room/${data._id}`)}>
            {cxtLang.lang === 'pt' && 'ASSISTIR'}
            {cxtLang.lang === 'en' && 'WATCH'}
            {cxtLang.lang === 'es' && 'VER'}
          </Button>
        )}
      </ContentActions>
    </Container>
  );
};
