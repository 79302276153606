import React from 'react';
import { Loader, hooks } from '@ederzadravec/jade-ui';

import { Store } from 'contexts';

const RoomContext = React.createContext({});

let WS = null;

const Provider = ({ children, room }) => {
  const { session, type, user } = React.useContext(Store.Context);
  const [loading, setLoading] = hooks.useState(true);
  const [attempts, setAttemps] = hooks.useState(0);
  const [state, setState] = hooks.useState({ loading: true });

  const setResult = ({ message, ...data }) => {
    const MESSAGES = {
      getRoom: () => {
        setLoading(false);

        const detach = data?.chat.find(item => item.detach);

        const chat = data?.chat.reduce((acc, item) => {
          return { ...acc, [item._id]: item };
        }, {});

        const questions = data?.questionTopic?.questions?.reduce((acc, item) => {
          return { ...acc, [item._id]: item };
        }, {});

        setState({
          ...(data || {}),
          chat,
          detach,
          question: { ...(data?.questionTopic || {}), questions },
        });
      },
      sendChat: () => {
        setState(prev => {
          const newChats = { ...prev.chat, [data.chat._id]: data.chat };

          const detach = Object.values(newChats).find(item => item.detach);

          return {
            ...prev,
            detach,
            chat: newChats,
          };
        });
      },
      sendQuiz: () => {
        setState(prev => ({
          ...prev,
          quiz: {
            refresh: new Date(),
            ...data.quiz,
          },
        }));
      },
      sendPoll: () => {
        setState(prev => {
          let userResponse = data?.poll?.userResponse;

          if (!prev?.poll?.userResponse && user.id !== data?.poll?.userResponse?.user) {
            userResponse = null;
          } else if (prev?.poll?.poll?._id !== data?.poll?.poll?._id) {
            console.log('poll else caiu aqui, alguem vai entender');
            //userResponse = null;
          }

          return {
            ...prev,
            poll: {
              refresh: new Date(),
              ...data?.poll,
              userResponse,
            },
          };
        });
      },
      sendHtml: () => {
        setState(prev => ({
          ...prev,
          html: {
            refresh: new Date(),
            ...data.html,
          },
        }));
      },
      sendQuestionTopic: () => {
        const questions = data?.questionTopic?.questions?.reduce((acc, item) => {
          return { ...acc, [item._id]: item };
        }, {});

        setState(prev => ({
          ...prev,
          question: {
            refresh: new Date(),
            ...data.questionTopic,
            questions: { ...(state?.question?.questions || {}), ...questions },
          },
        }));
      },
      sendEvaluation: () => {
        setState(prev => ({
          ...prev,
          evaluation: {
            refresh: new Date(),
            ...data.evaluation,
          },
        }));
      },
    };

    MESSAGES[message] && MESSAGES[message]();
  };

  const sendMessage = (data = {}) => {
    const payload = JSON.stringify(data);
    return WS.send(payload);
  };

  const getInitialData = async () => {
    const data = {
      message: 'getRoom',
      data: {
        session,
        type,
        room,
      },
    };

    await sendMessage(data);
  };

  const connectRoom = async () => {
    const WS_HOST = process.env.WS_HOST; //'ws://192.168.1.105:5000';
    //console.log({ WS_HOST });

    const ws = new WebSocket(WS_HOST);

    setAttemps(attempts + 1);

    ws.onopen = () => {
      WS = ws;
      getInitialData();
    };

    ws.onmessage = ({ data }) => {
      const message = JSON.parse(data);

      setResult(message?.success);
    };

    ws.onerror = data => {};
  };

  const sendData = async (message, data) => {
    const payload = {
      message,
      data: {
        session,
        type,
        room,
        ...data,
      },
    };

    await sendMessage(payload);
  };

  React.useEffect(() => {
    connectRoom();

    return () => {
      if (!WS) return;

      WS.close();
      setAttemps(0);
      WS = null;
    };
  }, []);

  const value = {
    quiz: state.quiz,
    poll: state.poll,
    html: state.html,
    chat: state.chat,
    detach: state.detach,
    question: state.question,
    evaluation: state.evaluation,
    sendChat: data => sendData('sendChat', data),
    sendQuiz: data => sendData('sendQuiz', data),
    sendPoll: data => sendData('sendPoll', data),
    sendQuestionTopic: data => sendData('sendQuestionTopic', data),
    sendQuestionTopicAction: data => sendData('sendQuestionTopicAction', data),
  };

  return (
    <RoomContext.Provider value={value}>
      <Loader show={loading} color="black" spinner="BeatLoader" size={20}>
        {children}
      </Loader>
    </RoomContext.Provider>
  );
};

export const Room = {
  Context: RoomContext,
  Provider,
  Consumer: RoomContext.Consumer,
};
