import React from 'react';
import styled from 'styled-components';

import { Text } from '../../Text/Text';
import { useService } from 'service';
import { Store } from 'contexts';

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 4px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid
    ${({ theme, reading }) => (reading ? theme.palette.black.light : 'transparent')};
  margin-bottom: 4px;
  height: 40px;
  padding: 12px;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  margin-bottom: 4px;
  padding: 12px;
`;

const SetRead = ({ setReading, data, reading, setViewed }) => {
  const [thisRead, setThisRead] = React.useState(null);
  const { user } = React.useContext(Store.Context);

  const [, {}, setIsReadNotify] = useService(
    'patch',
    `/notification/${data._id}/viewer-set-read`,
    {},
    false
  );

  const read = data?.isRead.find(v => v.viewer === user?.id);

  const HandleOnReading = async id => {
    setViewed();

    setReading(id);

    if (user.id === read?.viewer) {
      setReading(reading ? '' : id);
      return;
    }

    setThisRead(id);

    await setIsReadNotify();
  };
  //&& thisRead === data._id && no rever
  return (
    <>
      {user.id === read?.viewer && (
        <Text size="14" type="link" onClick={() => HandleOnReading(data._id)}>
          Rever
        </Text>
      )}

      {data.isAvailable && !read?.viewer && (
        <Text size="14" type="link" color="primary" onClick={() => HandleOnReading(data._id)}>
          {thisRead === data._id ? 'Lido' : 'Ler'}
        </Text>
      )}
    </>
  );
};

export const Item = ({ data, setViewed }) => {
  const [reading, setReading] = React.useState('');

  return (
    <Container>
      <Header reading={reading}>
        <Text size="16" color="primary" bold>
          {data.title}
        </Text>

        <SetRead data={data} setReading={setReading} reading={reading} setViewed={setViewed} />
      </Header>

      {reading === data._id && (
        <Content>
          <Text size="16" color="black">
            {data.content}ss
          </Text>
        </Content>
      )}
    </Container>
  );
};
