import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
`;

const ListaImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Image = styled.img`
  margin: 8px;
  width: 290px;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  border: 4px solid transparent;

  ${({ selected, theme }) =>
    selected &&
    `border: 4px solid ${theme.palette.primary.main};
  `}
`;

export const List = ({ mode, data, selected, setSelected, onClick, isInArray }) => {
  return (
    <Container>
      <ListaImages>
        {(data?.data || []).map(item => {
          return (
            <Image
              key={item.id}
              src={`https://api.hellomais.com.br/${item.cacheFilepath}`}
              onClick={() => onClick(selected, setSelected, item.id)}
              selected={isInArray(selected, item.id)}
            />
          );
        })}
      </ListaImages>
      <br />
    </Container>
  );
};
