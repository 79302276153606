import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button, Loader } from '@ederzadravec/jade-ui';
import { FaRegCheckCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

import { Text, Toastr } from 'components';
import { useService } from 'service';
import { dbToDate } from 'utils/date';

const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  margin-bottom: 24px;
  margin-right: 16px;
  z-index: 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContainerImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  background-size: cover;
  border-radius: 14px 14px 0 0;
  cursor: pointer;
`;

const IsSpanhol = styled(Text)`
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 10px 10px 0 0;
  padding: 5px 10px;
  margin-left: 10px;
  position: absolute;
  bottom: 0;
  right: 10px;
  display: inline;
`;

const NameRoom = styled(Text)`
  width: 100%;
  padding: 0 20px;
  text-transform: uppercase;
`;

const Badge = styled(Text)`
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 15px;
  padding: 5px 10px;
  margin-left: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline;
`;

const Content = styled.div`
  width: 100%;
  padding: 24px;
  line-height: 18px;
`;

const Title = styled(Text)`
  font-weight: 700;
`;

const Schedule = styled(Text)`
  margin: 4px 0;
`;

const Summary = styled(Text)`
  margin: 16px 0;
  line-height: 16px;
`;

const Checked = styled(FaRegCheckCircle)`
  font-size: 20px;
`;

const ButtonClosed = styled(Button)`
  background: ${({ theme }) => theme.palette.black.light};
  color: ${({ theme }) => theme.palette.black.main};
  cursor: not-allowed;
  border: 0;

  :hover {
    cursor: not-allowed;
  }
`;

export const CardRoom = ({ data, setShowDetail, schedule, setSchedule }) => {
  const history = useHistory();

  const [, { loading }, addToSchedule] = useService('post', `/app/schedule/${data._id}`, {}, false);

  const inShedule = data.scheduled;

  const handleOnSchedule = async data => {
    const result = await addToSchedule();

    if (result.success.status === 'CONTAIN') {
      Swal.fire({
        title: 'Você já possui uma palestra para esse horário!',
        confirmButtonText: 'Ok',
      });

      return;
    }

    Toastr.open({
      title: data.title + ' | Foi adicionado à sua agenda',
      labelButtom: 'Ok',
      time: 8000,
    });

    setSchedule(data._id);

    const link = `
    https://calendar.google.com/calendar/u/0/r/eventedit?text=${data.name}
    &dates=${dbToDate(data.openAt, "yyyyMMdd'T'HHmmss")}/${dbToDate(
      data.closeAt,
      "yyyyMMdd'T'HHmmss"
    )}
    &ctz=America/Sao_Paulo
    &details=${data.resumeDescription}
    &location=https://convencao2021.totvs.com
    &pli=1
    &uid=
    &sf=true
    &output=xml
    `;

    const win = window.open(link, '', 'width=800,height=600');
    win.focus();
  };

  return (
    <Container>
      <Loader direction="column">
        <ContainerImage onClick={() => setShowDetail(data._id)}>
          {data.isOnline && (
            <Badge type="span" color="white" bold size="10">
              AO VIVO
            </Badge>
          )}

          <NameRoom type="h1" size="22" color="white" bold>
            {data.name}
          </NameRoom>

          {data.linkEs && (
            <IsSpanhol type="span" color="white" bold size="12">
              Tradução espanhol
            </IsSpanhol>
          )}
        </ContainerImage>

        <Content>
          <Title type="h1" size="18" color="primary">
            {data.title}
          </Title>

          <Schedule type="p" size="14" color="primary">
            {dbToDate(data?.openAt, "dd 'de' MMMM HH'h'mm")}
          </Schedule>

          <Summary type="p" size="16" color="black">
            {data.resumeDescription}{' '}
            <Text
              type="link"
              bold
              color="primary"
              size="16"
              onClick={() => setShowDetail(data._id)}>
              ...Continuar Lendo
            </Text>
          </Summary>

          {data.isOnline && !data.isClosed && (
            <Button color="primary" onClick={() => history.push(`/app/room/${data._id}`)}>
              ASSISTIR
            </Button>
          )}

          {!data.isOnline && data.isClosed && (
            <ButtonClosed onClick={() => history.push(`/app/room/${data._id}`)}>
              Encerrado
            </ButtonClosed>
          )}

          {!data.isOnline && !data.isClosed && inShedule && !schedule && (
            <Button color="primary" iconAfter={Checked}>
              AGENDADO
            </Button>
          )}

          {!data.isOnline && !data.isClosed && !inShedule && data._id === schedule && (
            <Button color="primary" iconAfter={Checked}>
              AGENDADO
            </Button>
          )}

          {!data.isOnline && !data.isClosed && !inShedule && data._id !== schedule && (
            <Button
              color="primary"
              loading={loading}
              variant="outline"
              onClick={() => handleOnSchedule(data)}>
              SALVAR NA AGENDA
            </Button>
          )}
          <br />
        </Content>
      </Loader>
    </Container>
  );
};
