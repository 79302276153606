import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import IconeBrand from 'assets/images/brand.svg';

import { Text as CPText } from '../Text/Text';

const Container = styled.div`
  width: 300px;
  background-color: ${({ theme }) => theme.palette.black.main};
  min-height: 100vh;
  height: 100%;
  padding: 8px;
`;

const ImageContent = styled.div`
  padding: 24px;
  margin-bottom: 32px;
  border-bottom: 1px solid #fff;
`;

const Image = styled.img`
  max-width: 100%;
`;

const Itens = styled.div``;

const Item = styled.div`
  border: 1px solid #888;
  padding: 0px 8px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.palette.white.main};
  cursor: pointer;
  transition: all ease 0.3s;

  &:hover {
    padding: 0px 4px 0px 12px;
  }
`;

const ChildItens = styled.div`
  transition: all ease 0.5s;

  ${({ active }) => (active ? `height: auto;` : `height: 0; `)}
`;

const ChildItem = styled.div`
  transition: all ease 0.3s;
  ${({ active }) => (active ? `display: block;` : `display: none;`)}
  padding: 0px 8px;
  color: ${({ theme }) => theme.palette.white.main};
  cursor: pointer;

  &:first-child {
    border-top: 1px solid #888;
  }
  &:hover {
    padding: 0px 4px 0px 12px;
  }
`;

const Text = styled(CPText)`
  width: 100%;
  display: block;
  padding: 8px 0;
`;

export const SideBar = () => {
  const history = useHistory();
  const [value, setValue] = React.useState(null);

  const handleOnPush = route => {
    history.push(route);
  };

  const dataItens = [
    {
      name: 'Painel',
      path: '/admin',
      children: [],
      //children: [{ name: 'Novo', path: '/admin/user/new' }],
    },
    {
      name: 'Users',
      path: '/admin/user',
      children: [],
    },
    {
      name: 'Users Publicos',
      path: '/admin/viewer',
      children: [],
    },
    {
      name: 'Palestrantes',
      path: '/admin/speaker',
      children: [],
    },
    {
      name: 'Salas',
      path: '/admin/room',
      children: [],
    },
    {
      name: 'Ao Vivo',
      path: '/admin/in-live',
      children: [],
    },
    {
      name: 'Banners',
      path: '/admin/banner',
      children: [],
    },
    {
      name: 'Header',
      path: '/admin/header',
      children: [],
    },
    {
      name: 'Mural',
      path: '/admin/wall',
      children: [],
    },

    {
      name: 'Premiações',
      path: '/admin/award',
      children: [],
    },
    {
      name: 'Instagram',
      path: '/admin/instagram',
      children: [],
    },
    {
      name: 'Notificações',
      path: '/admin/notification',
      children: [],
    },
    {
      name: 'Configurações',
      path: '/admin/settings',
      children: [],
    },
    {
      name: 'Sair',
      path: '/logout',
      children: [],
    },
  ];

  return (
    <Container>
      <ImageContent>
        <Image src={IconeBrand} />
      </ImageContent>

      <Itens>
        {dataItens.map((item, key) => (
          <Item
            key={key}
            onMouseEnter={() => setValue(item.name)}
            onMouseLeave={() => setValue(null)}>
            <Text size="18" type="link" color="white" onClick={() => handleOnPush(item.path)}>
              {item.name}
            </Text>

            {item.children.length > 0 && (
              <ChildItens active={item.name === value}>
                {item.children.map((childItem, key) => (
                  <ChildItem
                    active={item.name === value}
                    key={key}
                    onClick={() => handleOnPush(childItem.path)}>
                    <Text size="16">{childItem.name}</Text>
                  </ChildItem>
                ))}
              </ChildItens>
            )}
          </Item>
        ))}
      </Itens>
    </Container>
  );
};
