import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FormGrid, hooks, TextInput, Button } from '@ederzadravec/jade-ui';

import { Space, Text } from 'components';
import { useService } from 'service';
import { ContextLanguage } from 'contexts';
import { formConfirm } from './Texts/FormConfirm'

const Container = styled.div`
  padding: 24px 0;
`;

const TitleForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelNotice = styled(Text)`
  margin-top: 24px;
  background: #eeeeee;
  border-radius: 8px;
  padding: 16px;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;

const validations = {};

export const FormConfirm = () => {
  const [notice, setNotice] = React.useState(false);
  const history = useHistory();
  const [, { loading }, validViewer] = useService('post', `/app/viewer/register`, null, false);

  const [form, onChange] = hooks.useForm({ validations });

  const cxtLang = useContext(ContextLanguage);

  useEffect(() => {
    cxtLang.setTexts(formConfirm);
  }, []);

  const handleOnSubmit = async () => {
    const data = {
      email: form.getValue('email'),
    };

    const result = await validViewer(data);

    if (result?.success?.status === 'OK') {
      setNotice('accept');
    }

    if (result?.error) {
      setNotice('fail');
    }
  };

  const formConfig = [
    [
      {
        schema: 'Space',
        size: { md: 12, xs: 12 },
        type: () => (
          <TitleForm>
            <Text type="h2" size="22" color="primary" bold>
              {cxtLang.texts[cxtLang.lang].textvalidation}
            </Text>
            <Space mt={1} />
            <hr />
            <Space mt={1} />
          </TitleForm>
        ),
      },
    ],

    [
      {
        schema: 'email',
        size: { md: 12, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].labelFieldEmail}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space mt={2} />

        <ContainerButton>
          <Button center loading={loading}>
          {cxtLang.texts[cxtLang.lang].labelButton}
          </Button>
        </ContainerButton>

        <Space mt={2} />

        <Text type="p" center>
          <Text color="primary" link bold onClick={() => history.push('/')}>
          {cxtLang.texts[cxtLang.lang].uriBackToLogin}
          </Text>
        </Text>
      </FormGrid>

      {notice === 'fail' && (
        <LabelNotice color="alert" center bold type="p">
          {cxtLang.texts[cxtLang.lang].textInvalidEmail} <Space mt={2} />
          {/* <Text type="link" color="black" onClick={() => history.push('/')}>
          {cxtLang.texts[cxtLang.lang].uriBackToLogin}
          </Text> */}
        </LabelNotice>
      )}

      {notice === 'accept' && (
        <LabelNotice color="black" center bold type="p">
          {cxtLang.texts[cxtLang.lang].textCompleteRegistration}
          <br />
          {/* <Text type="link" color="black" bold onClick={() => history.push('/')}>
          {cxtLang.texts[cxtLang.lang].uriBackToLogin}
          </Text> */}
        </LabelNotice>
      )}
    </Container>
  );
};
