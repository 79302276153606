import styled from 'styled-components';
import { Button, TableList, Loader } from '@ederzadravec/jade-ui';
import { Switch, Route } from 'react-router-dom';

import { Text } from 'components';
import { useService } from 'service';

import { Form } from './Form';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const TYPES = [
  {
    value: 'ADMIN',
    label: 'Administrador',
  },
  {
    value: 'USER',
    label: 'Usuário',
  },
];

export default ({ history }) => {
  const [userData, { loading }, refreshUsers] = useService('get', '/user?limit=1000');

  const BASE_URL = '/admin/user';

  const configList = [
    {
      name: 'Usuário',
      dataPath: 'name',
      size: {
        md: 3,
      },
    },
    {
      name: 'E-Mail',
      dataPath: 'email',
      size: {
        md: 5,
      },
    },
    {
      name: 'CPF',
      dataPath: 'cpf',
      size: {
        md: 2,
      },
    },
    {
      name: 'Açao',
      dataPath: data => (
        <Text type="link" color="black" onClick={() => history.push(BASE_URL + '/' + data._id)}>
          Editar
        </Text>
      ),
      size: {
        md: 2,
      },
    },
  ];

  return (
    <Container>
      <Header>
        <Text type="h3" size="24" bold color="black">
          Usuários
        </Text>

        <Button
          onClick={() => {
            history.push(`${BASE_URL}/new`);
          }}>
          Novo
        </Button>
      </Header>

      <Loader show={loading}>
        <Content>
          <TableList
            config={configList}
            data={userData?.success?.user?.data || []}
            // onSelect={() => {
            //   history.push(`${BASE_URL}/10`);
            // }}
          />
        </Content>
      </Loader>

      <Switch>
        <Route path={`${BASE_URL}/new`}>
          <Form
            onClose={() => {
              history.push(BASE_URL);
              refreshUsers();
            }}
          />
        </Route>
      </Switch>
    </Container>
  );
};
