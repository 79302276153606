import React from 'react';
import styled from 'styled-components';
import { hooks, FormGrid, TextInput } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { Text, Space, Button } from 'components';
import { useService } from 'service';

const Container = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const validations = {};

export const Form = ({ viewerData }) => {
  const viewer = viewerData?.success?.viewer || [];
  const [, { loading: editLoading }, update] = useService(
    'patch',
    `viewer/${viewer._id}`,
    null,
    false,
    [viewer]
  );

  const [form, onChange] = hooks.useForm({ validations });

  React.useEffect(() => {
    if (!viewerData?.success) {
      return;
    }

    form.setValues(viewer);
  }, [viewerData]);

  const handleOnSubmit = async () => {
    const data = {
      name: form.getValue('name'),
      email: form.getValue('email'),
      companyUnit: form.getValue('companyUnit'),
      companyPosition: form.getValue('companyPosition'),
    };

    const result = await update(data);

    if (result?.error) {
      return form.setError(result?.error);
    }

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: 'Dados atualizados com sucesso!',
        confirmButtonText: 'Ok',
      });
    }
  };

  const formConfig = [
    [
      {
        schema: 'name',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'email',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'companyUnit',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Unidade',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'companyPosition',
        size: { md: 6, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Cargo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h2" size="18" color="primary" bold>
        Dados Pessoais
      </Text>

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space mt={2} />

        <Button loading={editLoading}>Salvar</Button>
      </FormGrid>
    </Container>
  );
};
