import React, { useContext } from 'react';
import styled from 'styled-components';

import { Text, Space } from 'components';
import { Room } from 'contexts';
import AvatarTotvs from 'assets/images/avatarImage.png';
import { dbToDate, newDate, newDbDate } from 'utils/date';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  width: 100%;
  padding: 32px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Content = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid ${({ theme }) => theme.palette.primary.main};
  padding-right: 12px;
  white-space: nowrap;
  margin-right: 12px;

  @media (max-width: 768px) {
    border-right: 0px solid ${({ theme }) => theme.palette.primary.main};
    padding-bottom: 30px;
  }
`;

const Avatar = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 12px;
`;

const Message = styled(Text)`
  align-self: center;
`;

export const FeaturedMessages = ({ idRoom }) => {
  const { detach } = React.useContext(Room.Context);

  if (!detach?.content) return null;

  const cxtLang = useContext(ContextLanguage);

  return (
    <Container>
      <Text size="24" type="h3" bold color="primary">
        {cxtLang.lang === 'pt' && 'MENSAGENS EM DESTAQUE'}
        {cxtLang.lang === 'en' && 'FEATURED MESSAGES'}
        {cxtLang.lang === 'es' && 'MENSAJES DESTACADOS'}
      </Text>

      <Space mt={2} />

      <Content>
        <Avatar src={detach.viewer ? detach.viewer.photo : AvatarTotvs} />

        <ContentInfo>
          <Text size="18" semiBold type="h3" color="primary">
            {detach.user && 'Hello+'}
            {detach.viewer && detach.viewer.name}
          </Text>

          <Text size="16" semiBold type="p" color="primary">
            {cxtLang.lang === 'pt' && 'em: '}
            {cxtLang.lang === 'en' && 'in: '}
            {cxtLang.lang === 'es' && 'en: '}
            {dbToDate(detach.createdAt, 'HH:mm')}
          </Text>
        </ContentInfo>

        <Message
          type="p"
          size="18"
          color="primary"
          semiBold
          dangerouslySetInnerHTML={{ __html: detach.content }}
        />
      </Content>
    </Container>
  );
};
