import React from 'react';
import styled from 'styled-components';
import { Grid } from '@ederzadravec/jade-ui';

import { Space } from 'components';

import { Room } from 'contexts';
import { useService } from 'service';
import { Quiz } from './Quiz';
import { Html } from './Html';
import { Poll } from './Poll';
import { Question } from './Question/Question';
import { SelectLanguage } from '../SelectLanguage';
import { Evaluation } from '../Evaluation';

const ContainItem = styled.div`
  width: 100%;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LiveInteractions = ({ idRoom, language, handleOnToggles }) => {
  const { quiz, poll, html } = React.useContext(Room.Context);

  const [dataRoom, {}] = useService('get', `/app/room/${idRoom}`, {}, true, [idRoom]);

  return (
    <>
      <Grid container>
        <Grid size={{ xs: 12, md: 12 }}>
          <SelectLanguage
            language={language}
            handleOnToggles={handleOnToggles}
            IslinkEs={dataRoom?.success?.room?.linkEs}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 12 }}>
          <Evaluation linkEvaluation={dataRoom?.success?.room?.linkEvaluation} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid size={{ md: 12 }}>
          <Question />
        </Grid>

        {html?.html?.html && (
          <Grid size={{ xs: 12, md: 12 }}>
            <ContainItem>
              <Html />
            </ContainItem>
          </Grid>
        )}

        {quiz?.quiz?._id && (
          <Grid size={{ xs: 12, md: 12 }}>
            <ContainItem>
              <Quiz />
            </ContainItem>
          </Grid>
        )}

        {poll?.poll?._id && (
          <Grid size={{ xs: 12, md: 12 }}>
            <ContainItem>
              <Poll />
            </ContainItem>
          </Grid>
        )}
      </Grid>
    </>
  );
};
