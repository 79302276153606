import React from 'react';
import styled from 'styled-components';
import { Grid } from '@ederzadravec/jade-ui';
import { FaPen } from 'react-icons/fa';

import ImagePerfil from 'assets/images/avatarImage.png';
import { Text, Space } from 'components';
import { Form } from './Form';
import { FormPassword } from './FormPassword';
import { Store } from 'contexts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.black.light};
  padding-top: 24px;
  width: inherit;
`;

const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentPicture = styled.div`
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-top: 36px;
    padding-top: 20px;
  }
`;

const Picture = styled.img`
  background-color: #777;
  width: 100%;
  height: auto;
  border-radius: 14px;
  margin: 0 auto 24px;

  @media (max-width: 768px) {
    margin: 0 auto 24px;
  }
`;

const IconEdit = styled(FaPen)`
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
  display: inline-block;
`;

export default () => {
  const { user } = React.useContext(Store.Context);

  return (
    <Container>
      <Text color="black" type="h3" bold size="24">
        PREFERÊNCIAS
      </Text>

      <Space mt={4} />

      <Grid container>
        <Grid size={{ xs: 12, md: 9 }}>
          <ContentForm>
            <Form idUser={user?.id} />

            <Space mt={4} />

            <FormPassword idUser={user?.id} />
          </ContentForm>
        </Grid>

        <Grid size={{ xs: 12, md: 3 }}>
          <ContentPicture>
            <Text type="h2" size="22" color="primary" bold>
              Foto Perfil
            </Text>

            <Space mt={2} />

            <hr />

            <Space mt={3} />

            <Picture src={ImagePerfil} />
          </ContentPicture>
        </Grid>
      </Grid>
    </Container>
  );
};
