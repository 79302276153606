import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from '@ederzadravec/jade-ui';

import { Text, Space } from 'components';
import { Room } from 'contexts';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
  width: 100%;
`;

const ContainItem = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Evaluation = ({ linkEvaluation }) => {
  const { evaluation } = React.useContext(Room.Context);

  const cxtLang = useContext(ContextLanguage);

  if (!evaluation || !evaluation.isVisible) return null;

  const handleOnLink = () => {
    window.open(evaluation.link[cxtLang.lang], '_blank');
  };

  return (
    <Container>
      <ContainItem>
        <Text size="18" color="primary" type="h3" bold center>
          {cxtLang.lang === 'pt' && 'AVALIAÇÃO'}
          {cxtLang.lang === 'en' && 'ASSESSMENT '}
          {cxtLang.lang === 'es' && 'EVALUACIÓN'}
        </Text>

        <Space mt={2} />

        <ContentButton>
          <Button onClick={handleOnLink} center>
            {cxtLang.lang === 'pt' && 'INICIAR'}
            {cxtLang.lang === 'en' && 'START '}
            {cxtLang.lang === 'es' && 'COMIENZO'}
          </Button>
        </ContentButton>
      </ContainItem>
    </Container>
  );
};
