import React from 'react';

import { Container, Image } from './style';
import { Box } from 'components';

import Image1 from 'assets/images/temp/agenda1.jpg';
import Image2 from 'assets/images/temp/agenda2.png';

export default () => {
  return (
    <Container>
      <Box>
        <Image src={Image1} />
        <Image src={Image2} />
      </Box>
    </Container>
  );
};
