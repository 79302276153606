import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  hooks,
  FormGrid,
  TextInput,
  TextArea,
  PhotoUpload,
  Select,
  Button,
} from '@ederzadravec/jade-ui';

import { Text, Space } from 'components';
import { useService } from 'service';
import { fileToBase64, validFile } from 'utils/file';

const Container = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const validations = {
  name: [[value => !value, 'Campo obrigatório']],
  image: [
    [
      value => value && value?.length && !validFile(value[0], ['png', 'jpeg', 'jpg']),
      'Arquivo não permitido',
    ],
  ],
};

const SIZE = [
  { value: 'BIG', label: 'Grande' },
  { value: 'MEDIUM', label: 'Médio' },
];

export const Form = ({ bannerData }) => {
  const history = useHistory();

  const [, { loading: saveLoading }, saveBanner] = useService('post', '/banner', {}, false);
  const [, { loading: updateLoading }, updateBanner] = useService(
    'patch',
    `banner/${bannerData?.success?.banner?._id}`,
    null,
    false,
    [bannerData]
  );

  const [form, onChange] = hooks.useForm({ validations });

  const data = bannerData?.success?.banner;

  React.useEffect(() => {
    if (!data?._id) {
      return;
    }

    const size = SIZE.find(item => item.value === data?.size);

    const image = data?.image ? [data?.image] : []; // transformar string em array
    const imageMobile = data?.imageMobile ? [data?.imageMobile] : [];

    form.setValues({ ...data, size, image, imageMobile });
  }, [bannerData]);

  const handleOnSubmit = async () => {
    const image = form.getValue('image');
    const photoBase64 = image && image.length ? await fileToBase64(image[0]) : null;

    const imageMobile = form.getValue('imageMobile');
    const photoMobileBase64 =
      imageMobile && imageMobile.length ? await fileToBase64(imageMobile[0]) : null;

    const data = {
      name: form.getValue('name'),
      title: form.getValue('title'),
      description: form.getValue('description'),
      link: form.getValue('link'),
      size: form.getValue('size') ? form.getValue('size').value : 'BIG',
      image: photoBase64,
      imageMobile: photoMobileBase64,
    };

    const result = form.getValue('_id') ? await updateBanner(data) : await saveBanner(data);

    if (result?.error) {
      return form.setError(result?.error);
    }

    if (result?.success?.status === 'OK') {
      return history.push('/admin/banner');
    }
  };

  const formConfig = [
    [
      {
        schema: 'name',
        size: { md: 12, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'title',
        size: { md: 12, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'description',
        size: { md: 12, xs: 12 },
        type: TextArea,
        props: schema => ({
          label: 'Descrição',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],

    [
      {
        schema: 'link',
        size: { md: 12, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'URL Botão',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],

    [
      {
        schema: 'size',
        size: { md: 12, xs: 12 },
        type: Select,
        props: schema => ({
          label: 'Tipo',
          data: SIZE,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],

    [
      {
        schema: 'image',
        size: { md: 3, xs: 12 },
        type: PhotoUpload,
        props: schema => ({
          label: 'Imagem Desktop',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
      {
        schema: 'imageMobile',
        size: { md: 3, xs: 12 },
        type: PhotoUpload,
        props: schema => ({
          label: 'Imagem Mobile',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h2" size="18" color="primary" bold>
        Dados do Banner
      </Text>

      <Space mt={2} />

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Button loading={updateLoading || saveLoading}>Salvar</Button>
      </FormGrid>
    </Container>
  );
};
