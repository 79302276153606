import React, { useContext, useEffect } from 'react';
import { hooks, FormGrid, TextInput, Button } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { Space, Text } from 'components';
import { useService } from 'service';
import { formRegister } from './Texts';
import { useState } from '@ederzadravec/jade-ui/build/hooks';
import { ContextLanguage } from 'contexts';
import { Store } from 'contexts';

import { Container, ContainerButton } from './style';

const validations = {
  name: [[value => !value, 'Campo obrigatório']],
  position: [[value => !value, 'Campo obrigatório']],
  email: [[value => !value, 'Campo obrigatório']],
  password: [[value => !value, 'Campo obrigatório']],
  rePassword: [
    [value => !value, 'Campo obrigatório'],
    [(value, form) => value !== form.password, 'Senhas divergentes'],
  ],
};

export const FormRegister = ({ setAction }) => {
  const [error, setError] = useState('');
  const [, { loading }, register] = useService('post', `/app/viewer/register-to-form`, null, false);
  const [, {}, login] = useService('post', `app/login`, null, false);
  const { setSession, setStore } = React.useContext(Store.Context);

  const cxtLang = useContext(ContextLanguage);
  const history = useHistory();

  useEffect(() => {
    cxtLang.setTexts(formRegister);
  }, []);

  const [form, onChange] = hooks.useForm({ validations });

  const handleOnSubmitRecover = async () => {
    const data = {
      email: form.getValue('email').toLowerCase().replace(' ', ''),
      name: form.getValue('name'),
      position: form.getValue('position'),
      password: form.getValue('password'),
    };

    const result = await register(data);

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: `Cadastro e login realizado!`,
        confirmButtonText: 'Ok',
      }).then(result => {
        if (result.isConfirmed) {
          (async () => {
            const resultLogin = await login({ email: data.email, password: data.password });

            if (resultLogin?.success?.status === 'OK') {
              setStore({
                user: resultLogin.success.user,
              });

              setSession({
                session: resultLogin.success.token,
                type: resultLogin.success.user.type,
              });

              return history.push('/app');
            }
          })();
        }
      });
    }

    if (result?.error) {
      if (result?.error?.status === 'ERROR') {
        setError(result?.error?.msg);

        setInterval(() => {
          setError('');
        }, 5000);
      }
      return form.setError(result?.error);
    }
  };

  const formConfigRecover = [
    [
      {
        schema: 'name',
        size: { xs: 12, md: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].name}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'position',
        size: { xs: 12, md: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].labelOffice}`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'email',
        size: { xs: 12, md: 12 },
        type: TextInput,
        props: schema => ({
          label: `E-mail`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'password',
        size: { xs: 12, md: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].labelPassword}`,
          type: 'password',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'rePassword',
        size: { xs: 12, md: 12 },
        type: TextInput,
        props: schema => ({
          label: `${cxtLang.texts[cxtLang.lang].labelConfirmationPassword}`,
          type: 'password',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h3">{cxtLang.texts[cxtLang.lang].title}</Text>

      <FormGrid config={formConfigRecover} onSubmit={form.trySave(handleOnSubmitRecover)}>
        <Space mt={1} />

        {error && (
          <>
            <Space mt={2} />
            <Text type="p" center>
              {error}
            </Text>
            <Space mt={2} />
          </>
        )}

        <ContainerButton>
          <Button style={{ minWidth: '100%' }} color="primary" fontWeight={600} loading={loading}>
            {cxtLang.texts[cxtLang.lang].labelButton}
          </Button>
        </ContainerButton>
      </FormGrid>

      <Space mt={1} />

      <Text type="p" center>
        <Text
          color="black"
          width={100}
          fontWeight={600}
          link
          bold
          onClick={() => setAction('login')}>
          {formRegister[cxtLang.lang].uriBackToLogin}
        </Text>
      </Text>
    </Container>
  );
};
