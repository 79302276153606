import styled from 'styled-components'
import { FaCaretDown } from 'react-icons/fa'

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-height: 50px;
  z-index: 10;
`;

export const Label = styled.div`
  background: #fff;
  font-size: 12px;
  padding: 4px;
  color: ${({ theme, error }) => !error ? theme.palette.primary.main : theme.palette.alert.main};
`;

export const LabelError = styled.div`
  font-size: 12px;
  position: absolute;
  padding: 4px;
  left: 12px;
  color: ${({ theme }) => theme.palette.alert.main};
`;

export const Content = styled.div`
  border: 1px solid ${({ theme, error }) => !error ? theme.palette.primary.main : theme.palette.alert.main};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  cursor: pointer;
`;

export const Selected = styled.div`
  padding: 8px;
`;

export const ContentIcon = styled.div`
  width: 36px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 10px 10px 0px;
  border-left: 1px solid ${({ theme, error }) => !error ? theme.palette.primary.main : theme.palette.alert.main};
  padding: 6px;
`;

export const Icon = styled(FaCaretDown)`
  font-size: 22px;
  color: ${({ theme, error }) => !error ? theme.palette.primary.main : theme.palette.alert.main};
`;

export const ContentOption = styled.div`
  background: #fff;
  border-radius: 10px;
  margin: 8px;
  max-height: 160px;
  overflow: auto;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(222,222,222,0.75);
  -moz-box-shadow: 0px 2px 10px 0px rgba(222,222,222,0.75);
  box-shadow: 0px 2px 10px 0px rgba(222,222,222,0.75);

  &::-webkit-scrollbar{
      width: 4px;
      height: 8px;
    }
    &::-webkit-scrollbar-button{}
    &::-webkit-scrollbar-track{
      background: ${({ theme }) => theme.palette.white.light};
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track-piece{}
    &::-webkit-scrollbar-thumb{
      background: ${({ theme }) => theme.palette.primary.main};
      border-radius: 8px;
    }
    &::-webkit-scrollbar-corner{}
    &::-webkit-resizer{}
`;

export const Option = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;

  ${({ disabled }) => disabled ? `cursor: not-allowed;` : ``};

  &:hover{
    background:  ${({ theme }) => theme.palette.black.light};
  }

  &:last-child{
    border-bottom: 1px solid transparent;
  }
`;
