import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input`
  padding: 10px 8px;
  border-radius: 10px;
  width: 100%;
  font-size: 18px;
  border: 1px solid
    ${({ theme, error }) => (!error ? theme.palette.primary.main : theme.palette.alert.main)};

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Result = styled.div`
  display: ${({ visible }) => (visible ? `block` : `none`)};

  position: absolute;
  top: 50px;
  background-color: #fff;
  width: 100%;
  padding: 4px 16px;
  max-height: 200px;
  overflow: auto;
  box-sizing: border-box;
  z-index: 5;
  border-radius: 4px;
`;

const ItemResult = styled.h3`
  margin-bottom: 4px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    background: #dedede;
  }
`;

export const Search = ({ data, route, ...props }) => {
  const [input, setInput] = React.useState('');
  const [showResult, setShowResult] = React.useState(false);

  const history = useHistory();

  const handleOnChange = e => {
    e.preventDefault();

    e.target.value ? setShowResult(true) : setShowResult(false);

    setInput(e.target.value);
  };

  if (data?.length > 0) {
    data = data.filter(i => {
      var name = R.toLower(i.name);
      return name.match(R.toLower(input));
    });
  }

  const handleOnPush = id => {
    history.push(`/${preRoute}/${id}`);

    setShowResult(false);

    setInput('');
  };

  return (
    <Container {...props}>
      <Input type="text" placeholder="Pesquisar..." onChange={handleOnChange} value={input} />
      <Result visible={showResult}>
        {data.map((item, key) => (
          <ItemResult
            key={key}
            type="h3"
            size="18"
            color="black"
            onClick={() => {
              handleOnPush(item._id);
            }}>
            {item.name}
          </ItemResult>
        ))}
      </Result>
    </Container>
  );
};

Text.defaultProps = {
  route: '',
};
