import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { hooks, FormGrid, TextInput, Button } from '@ederzadravec/jade-ui';

import { Text, Space } from 'components';
import { useService } from 'service';

const Container = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const validations = {
  name: [[value => !value, 'Campo obrigatório']],
  title: [[value => !value, 'Campo obrigatório']],
  content: [[value => !value, 'Campo obrigatório']],
};

export const Form = ({ notificationData, onClose }) => {
  const history = useHistory();

  const [, { loading: saveLoading }, saveNotification] = useService(
    'post',
    '/notification',
    {},
    false
  );

  const [, { loading: updateLoading }, updateNotification] = useService(
    'patch',
    `notification/${notificationData?.success?.notification?._id}`,
    null,
    false,
    [notificationData]
  );

  const [form, onChange] = hooks.useForm({ validations });

  const data = notificationData?.success?.notification;

  React.useEffect(() => {
    if (!data?._id) return;

    form.setValues(data);
  }, [notificationData]);

  const handleOnSubmit = async () => {
    const data = {
      name: form.getValue('name'),
      title: form.getValue('title'),
      content: form.getValue('content'),
    };

    const result = form.getValue('_id')
      ? await updateNotification(data)
      : await saveNotification(data);

    if (result?.error) {
      return form.setError(result?.error);
    }

    if (result?.success?.status === 'OK') {
      onClose(true);
    }
  };

  const formConfig = [
    [
      {
        schema: 'name',
        size: { md: 12, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'title',
        size: { md: 12, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Titulo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'content',
        size: { md: 12, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Conteúdo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h2" size="18" color="primary" bold>
        Dados da Notificação
      </Text>

      <Space mt={2} />

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Button loading={updateLoading || saveLoading}>Salvar</Button>
      </FormGrid>
    </Container>
  );
};
