import React, { useState } from 'react';
import { Button, Loader, TextInput } from '@ederzadravec/jade-ui';
import axios from 'axios';

import { Text } from 'components';
import { List } from './List';

import { addRemoveItem, isInArray } from './functions';

import { Container, Header, Content, ContentButtons, SearchContent } from './styles';

export default ({ history }) => {
  const [dataPosts, setDataPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('initial');
  const [tag, setTag] = useState('Hellomais');
  const [token, setToken] = useState(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZyb250aWVyc0BkaWVnb3pmLmNvbS5iciIsImlhdCI6MTYzMTczMDk5OCwiZXhwIjoxNjMyMzM1Nzk4fQ.WNE8qg5egpMJ81ZJiha1V1JeU1CNpvGnsK1gUxUYLek'
  );
  const [selected, setSelected] = useState([]);

  const handleOnSearch = async () => {
    setMode('search');

    setDataPosts([]);
    setLoading(true);
    const result = await axios.get(
      `https://api.hellomais.com.br/api/enterprise/instagram/get_media_by_hashtag?tagName=${tag}&maxToSave=10&willBeSaved=false`,
      {
        headers: {
          'x-user-token': token,
        },
      }
    );
    setLoading(false);

    setDataPosts(result);
  };

  const handleOnSearchInDb = async () => {
    setMode('manager');

    setDataPosts([]);
    setLoading(true);
    const result = await axios.get(
      `https://api.hellomais.com.br/api/enterprise/instagram/all/public`,
      {
        headers: {
          'x-user-token': token,
        },
      }
    );
    setLoading(false);

    setDataPosts(result.data);
  };

  const handleOnSave = async () => {
    setLoading(true);
    const result = await axios.post(
      `https://api.hellomais.com.br/api/enterprise/instagram/save`,
      {
        isPublic: true,
        ids: selected,
      },
      {
        headers: {
          'x-user-token': token,
        },
      }
    );
    setLoading(false);
    setMode('initial');

    setDataPosts([]);
    setSelected([]);

    console.log('eee', result);

    if (result?.statusText === 'OK') {
      alert('Salvo');
    }
  };

  const handleOnDelete = async () => {
    setLoading(true);
    const result = await axios.put(
      `https://api.hellomais.com.br/api/enterprise/instagram/delete`,
      {
        ids: selected,
      },
      {
        headers: {
          'x-user-token': token,
        },
      }
    );
    setLoading(false);
    setMode('initial');

    setDataPosts([]);
    setSelected([]);

    if (result?.statusText === 'OK') {
      alert('Deletado');
    }
  };

  return (
    <Container>
      <Header>
        <Text type="h3" size="24" bold color="black">
          Instagram
        </Text>

        <SearchContent>
          <TextInput onChange={(e, value) => setToken(value)} value={token} label="Token" />
          <TextInput onChange={(e, value) => setTag(value)} value={tag} label="HashTag" />
        </SearchContent>

        <ContentButtons>
          {mode === 'initial' && selected.length === 0 && (
            <Button onClick={() => handleOnSearch()}>
              {loading ? 'Buscando..' : 'Buscar no Instagram'}
            </Button>
          )}

          {mode === 'initial' && selected.length === 0 && (
            <Button onClick={() => handleOnSearchInDb()}>
              {loading ? 'Buscando..' : 'Buscar no Banco'}
            </Button>
          )}

          {mode === 'search' && selected.length > 0 && (
            <Button onClick={() => handleOnSave()}>{loading ? 'Salvando..' : 'Salvar'}</Button>
          )}

          {mode === 'manager' && selected.length > 0 && (
            <Button onClick={() => handleOnDelete()}>{loading ? 'Deletando..' : 'Deletar'}</Button>
          )}
        </ContentButtons>
      </Header>

      <Content>
        <Loader show={loading}>
          {dataPosts.length === 0 ? (
            'Sem posts'
          ) : (
            <List
              data={dataPosts}
              mode={mode}
              selected={selected}
              isInArray={isInArray}
              setSelected={setSelected}
              onClick={addRemoveItem}
            />
          )}
        </Loader>
      </Content>
    </Container>
  );
};
