import React, { useContext } from 'react';
import styled from 'styled-components';
import { hooks, FormGrid as JDFormGrid, TextInput, Button } from '@ederzadravec/jade-ui';
import ScrollableFeed from 'react-scrollable-feed';

import AvatarTotvs from 'assets/images/avatarImage.png';
import { Room } from 'contexts';
import { Text } from 'components';
import { dbToDate } from 'utils/date';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  padding: 24px;
  position: relative;
  flex: 1;
`;

const Messages = styled.div`
  height: 440px;
  overflow: auto;

  @media (max-width: 1366px) {
    height: 240px;
  }
`;

const Message = styled.div`
  margin: 8px 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black.light};
`;

const HeaderMessage = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 10px;
`;

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TextMessage = styled(Text)`
  margin-bottom: 8px;

  font-weight: ${({ isAdmin }) => (isAdmin ? 900 : 'normal')};
  font-size: ${({ isAdmin }) => isAdmin && '16px'};
`;

const CustonTextArea = styled(TextInput)`
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  width: 100%;
  margin: 32px auto 0;
  box-sizing: border-box;
`;

const FormGrid = styled(JDFormGrid)`
  max-height: 140px;
`;

const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const validations = {
  content: [[value => !value, 'Deve ser preenchido']],
};

export const Chat = ({ idRoom }) => {
  const [loading, setLoading] = React.useState(false);
  const { chat, sendChat } = React.useContext(Room.Context);

  const listOfChat = Object.values(chat || {}) || [];

  const [form, onChange] = hooks.useForm({ validations });

  React.useEffect(() => {
    setLoading(false);
  }, [chat]);

  const handleOnSubmit = async () => {
    form.clear();

    setLoading(true);

    const data = {
      content: form.getValue('content'),
    };

    sendChat(data);
  };

  const formConfig = [
    [
      {
        schema: 'content',
        size: { xs: 12, md: 12 },
        type: CustonTextArea,
        props: schema => ({
          label: '',
          debounce: 0,
          placeholder: `${
            (cxtLang.lang === 'pt' && 'Digite uma mensagem') ||
            (cxtLang.lang === 'en' && 'Enter a message') ||
            (cxtLang.lang === 'es' && 'Ingrese un mensaje')
          }`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  const cxtLang = useContext(ContextLanguage);

  return (
    <Container>
      <Text type="h3" size="24" color="primary" bold>
        {cxtLang.lang === 'pt' && 'BATE-PAPO'}
        {cxtLang.lang === 'en' && 'CHAT'}
        {cxtLang.lang === 'es' && 'CHAT'}
      </Text>

      <Messages>
        <ScrollableFeed forceScroll>
          {listOfChat
            .filter(item => item?.hide !== true)
            .map((item, key) => (
              <Message key={item._id}>
                <HeaderMessage>
                  {item.viewer && <Avatar src={item.viewer.photo} />}
                  {item.user && <Avatar src={AvatarTotvs} />}

                  <ContentInfo>
                    <Text size="18" semiBold type="h3" color="primary">
                      {item.user && 'Hello+'}
                      {item.viewer && item.viewer.name}
                    </Text>

                    <Text size="16" semiBold type="p" color="primary">
                      em: {dbToDate(item.createdAt, 'HH:mm')}
                    </Text>
                  </ContentInfo>
                </HeaderMessage>

                {item.user ? (
                  <TextMessage
                    type="p"
                    color="black"
                    size="14"
                    isAdmin={item.user}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                ) : (
                  <TextMessage type="p" color="black" size="14" isAdmin={item.user}>
                    {item.content}
                  </TextMessage>
                )}
              </Message>
            ))}
        </ScrollableFeed>
      </Messages>

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <ContainerButtons>
          <Button loading={loading} center color="primary">
            {cxtLang.lang === 'pt' && 'ENVIAR'}
            {cxtLang.lang === 'en' && 'SEND'}
            {cxtLang.lang === 'es' && 'ENVIAR'}
          </Button>
        </ContainerButtons>
      </FormGrid>
    </Container>
  );
};
