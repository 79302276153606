import styled from 'styled-components';
import { hooks, FormGrid, Button, TextInput } from '@ederzadravec/jade-ui';

import { Modal, Space } from 'components';
import { useService } from 'service';

const Container = styled(Modal)``;

const validations = {
  name: [[value => !value, 'Campo obrigatório']],
  email: [[value => !value, 'Campo obrigatório']],
  companyUnit: [[value => !value, 'Campo obrigatório']],
  companyPosition: [[value => !value, 'Campo obrigatório']],
};

export const CompleteForm = ({ onClose }) => {
  const [, { loading }, saveViewer] = useService('post', '/viewer/new-complete', {}, false);
  const [form, onChange] = hooks.useForm({ validations });

  const handleOnSubmit = async () => {
    const data = {
      name: form.getValue('name'),
      email: form.getValue('email'),
      companyUnit: form.getValue('companyUnit'),
      companyPosition: form.getValue('companyPosition'),
    };

    const result = await saveViewer(data);

    if (result?.success?.status === 'OK') {
      return onClose();
    }

    if (result.error.status === 'FORM') {
      return form.setError(result.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'name',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'email',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'companyUnit',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Unidade',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
    [
      {
        schema: 'companyPosition',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Cargo',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];
  return (
    <Container size={{ md: 6 }} title="Cadastro Completo de Usuário Publico" onClose={onClose}>
      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Space mt={3} />
        <Button loading={loading}>Enviar</Button>
      </FormGrid>
    </Container>
  );
};
