import React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { AiOutlineLike, AiFillLike, AiOutlineDislike, AiFillDislike } from 'react-icons/ai';
import { Space } from '@ederzadravec/jade-ui';
import ScrollableFeed from 'react-scrollable-feed';
import { BeatLoader } from 'react-spinners';

import { Text } from 'components';
import { Store, Room } from 'contexts';

const Container = styled(ScrollableFeed)`
  margin: 16px 0 32px;
  max-height: 300px !important;
  overflow: auto;
`;

const ContainerItem = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const ContentQuestion = styled.div`
  flex-direction: column;
`;

const ContentLikes = styled.div`
  display: flex;
  margin-bottom: auto;
  justify-content: center;
  align-items: center;
`;

const Like = styled(Text)`
  cursor: pointer;
`;

const IconLike = styled.span`
  font-size: 16px;
  margin-left: 4px;
  margin-right: 8px;
  align-self: center;
`;

const UnLike = styled(Text)`
  cursor: pointer;
`;

const IconUnLike = styled.span`
  font-size: 16px;
  margin-left: 4px;
  margin-right: 8px;
  align-self: center;
`;

const Item = ({ data }) => {
  const [loading, setLoading] = React.useState(false);
  const { sendQuestionTopicAction } = React.useContext(Room.Context);
  const theme = React.useContext(ThemeContext);

  const handleOnLiked = action => {
    setLoading(true);
    sendQuestionTopicAction({
      question: data._id,
      action,
    });
  };

  React.useEffect(() => {
    setLoading(false);
  }, [data]);

  return (
    <ContainerItem>
      <ContentQuestion>
        <Text color="primary" size="16" bold>
          {`${data.viewer.name}: `}
        </Text>

        <Space top={1} />

        <Text color="black" size="16">
          {data.question}
        </Text>
      </ContentQuestion>

      <ContentLikes>
        {loading ? (
          <BeatLoader size={10} color={theme.palette.primary.main} />
        ) : (
          <>
            <Like
              color="primary"
              size="16"
              bold
              onClick={() => !data.userLiked && handleOnLiked('LIKE')}>
              {data.like} <IconLike as={data.userLiked ? AiFillLike : AiOutlineLike} />
            </Like>

            <UnLike
              color="primary"
              size="16"
              bold
              onClick={() => !data.userUnliked && handleOnLiked('UNLIKE')}>
              {data.unlike} <IconUnLike as={data.userUnliked ? AiFillDislike : AiOutlineDislike} />
            </UnLike>
          </>
        )}
      </ContentLikes>
    </ContainerItem>
  );
};

export const List = () => {
  const { question } = React.useContext(Room.Context);
  const { user } = React.useContext(Store.Context);

  const questions = Object.values(question?.questions || []);

  const sortedQuestions = questions
    .map(question => {
      const like = question?.like?.length || 0;
      const userLiked = question?.like?.find(item => item.viewer === user.id);

      const unlike = question?.unlike?.length || 0;
      const userUnliked = question?.unlike?.find(item => item.viewer === user.id);

      return {
        ...question,
        like,
        userLiked,
        unlike,
        userUnliked,
      };
    })
    .sort((x, y) => y.like - x.like);

  return (
    <Container>
      {sortedQuestions.map(item => (
        <Item key={item._id} data={item} />
      ))}
    </Container>
  );
};
