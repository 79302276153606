import React, { useState } from 'react';
import { Grid } from '@ederzadravec/jade-ui';

import LogoBrand from 'assets/images/brand.svg';
import ImageLeft from 'assets/images/image_left.svg';

import { Wellcome } from './Wellcome';
import { FormLogin } from './FormLogin';
import { FormRecover } from './FormRecover';
import { FormRegister } from './FormRegister';

import { Flags } from './Flags';

import { Container, ContentForm, ImageLogo, Image, Footer } from './style';

export default () => {
  const [action, setAction] = useState('wellcome');

  const render = () => {
    switch (action) {
      case 'wellcome':
        return <Wellcome setAction={setAction} />;
      case 'recover':
        return <FormRecover setAction={setAction} />;
      case 'login':
        return <FormLogin setAction={setAction} />;
      case 'register':
        return <FormRegister setAction={setAction} />;
    }
  };

  return (
    <Container>
      <Flags />

      <Grid size={{ md: 6, xs: 12 }}>
        <Image src={ImageLeft} alt="Logo" />
      </Grid>

      <Grid size={{ md: 6, xs: 12 }}>
        <ContentForm>
          <ImageLogo src={LogoBrand} alt="Logo" />

          <>{render()}</>
        </ContentForm>
      </Grid>

      <Footer center bold>
        Desenvolvido por BTO+ Comunicação e Deploy. Digital Business
      </Footer>
    </Container>
  );
};
