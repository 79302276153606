import React, { useContext } from 'react';
import styled from 'styled-components';
import { Select } from '@ederzadravec/jade-ui';
import { useLocation } from 'react-router-dom';

import { Text, Space } from 'components';
import { useService } from 'service';
import { getQuery } from 'utils/url';

import { Summary } from './Summary';
import { Interactions } from './Interactions';
import { StatisticsByUser } from './StatisticsByUser';
import { ChatHistory } from './ChatHistory';
import { QuestionsSent } from './QuestionsSent';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContetSelect = styled.div`
  width: 300px;
`;

export default ({ history }) => {
  const location = useLocation();
  const query = getQuery(location.search);

  const cxtLang = useContext(ContextLanguage);

  const [dataSearch, { loading }, fetchSearch] = useService(
    'get',
    `/app/room/rooms-search?limit=100`,
    {},
    true
  );

  const rooms = dataSearch?.success?.rooms?.data || [];
  const selectedRoom = rooms.find(item => item._id === query.room);

  const dataSelect = rooms.map(romm => {
    return {
      value: romm._id,
      label: romm.name.pt,
    };
  });

  const handleOnChange = (e, value) => {
    if (!value) {
      history.push(`/admin`);
    }

    if (value) {
      history.push(`/admin/?room=${value.value}`);
    }
  };

  return (
    <Container>
      <Header>
        <Text type="h3" size="24" bold color="black">
          Dashboard
        </Text>

        <Text type="h3" size="14" bold color="black">
          {selectedRoom && `Você esta Vendo Detalhes da sala: ${selectedRoom.name[cxtLang.lang]}`}
        </Text>

        <ContetSelect>
          {!loading && (
            <Select
              data={dataSelect}
              value={selectedRoom}
              label="Selecione uma Sala"
              placeholder="Selecione"
              onChange={handleOnChange}
            />
          )}
        </ContetSelect>
      </Header>

      <Space mt={2} />

      <hr />

      <Summary />

      {selectedRoom && <Interactions />}

      <StatisticsByUser />

      <ChatHistory />

      <QuestionsSent />
    </Container>
  );
};
