import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useService } from 'service';
import { Text } from 'components';
import { Info } from './Info';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.black.light};
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default ({ history }) => {
  let { id } = useParams();

  const [data, { loading }] = useService('get', `viewer/${id}`);

  return (
    <Container>
      <Header>
        <Text type="h3" size="24" bold color="primary">
          {data?.success?.viewer?.name}
        </Text>
      </Header>

      <Info viewerData={data} />
    </Container>
  );
};
