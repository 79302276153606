import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.black.light};
`;

export const ContentButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 500px;
`;

export const SearchContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  align-items: center;
`;