import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Space } from '@ederzadravec/jade-ui';

import { dbToDate, dateToDb } from 'utils/date';
import { Text } from 'components';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  background-size: cover;
  width: 100%;
  padding: 24px 8px;
  margin-bottom: 24px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    padding: 24px 8px;
    flex-wrap: wrap;
  }
`;

const ContentDays = styled.div`
  display: flex;
  margin-right: 8px;

  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    margin: unset;
    flex-wrap: wrap;
  }
`;

const ButtonDay = styled.button`
  margin: 0px 8px 8px 0px;
  padding: 16px 8px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  background-color: ${({ outline, theme }) =>
    outline ? `${theme.palette.secondary.dark}` : theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.white.main};
  padding: 16px 32px 16px 32px;
  border-radius: 10px;

  font-size: 16px;

  @media (max-width: 768px) {
    margin: 0px 8px 8px 0px;
    max-width: unset;
    width: 100%;
  }
`;

export const SelectDayRoom = ({ data, selectedDate, clearSelectDate }) => {
  const history = useHistory();
  const cxtLang = useContext(ContextLanguage);

  const handleSelectDay = day => {
    if (day === selectedDate) {
      clearSelectDate();
      history.push(`/app`);
    } else {
      history.push(`/app?date=${day}`);
    }
  };

  return (
    <Container>
      <Text type="h2" color="secondary" size={24} bold>
        {cxtLang.lang === 'pt' && 'Programação'}
        {cxtLang.lang === 'en' && 'Schedule'}
        {cxtLang.lang === 'es' && 'Horarios'}
      </Text>

      <Space top={2} />

      <ContentDays>
        {data.map((day, key) => (
          <ButtonDay
            outline={day === selectedDate}
            key={key}
            color="primary"
            onClick={() => handleSelectDay(day)}>
            {dbToDate(dateToDb(day), "dd '/' MM ")}
          </ButtonDay>
        ))}
      </ContentDays>
    </Container>
  );
};
