import React, { useContext } from 'react';
import styled from 'styled-components';

import { Room } from 'contexts';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
  border: 0px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  padding: 8px;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Html = ({ idRoom }) => {
  const { html: data } = React.useContext(Room.Context);

  const cxtLang = useContext(ContextLanguage);

  const html = data?.html?.html?.[cxtLang.lang];

  return <Container dangerouslySetInnerHTML={{ __html: html }} />;
};
