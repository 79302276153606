import styled from 'styled-components';
import { Loader } from '@ederzadravec/jade-ui';
import { useLocation } from 'react-router-dom';

import { Box, Text, Space } from 'components';
import { useService } from 'service';
import { getQuery } from 'utils/url';

const Container = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

const Content = styled.div`
  display: flex;
  width: max-content;
  flex-direction: row;
`;

const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 450px;
  margin-right: 16px;
`;

const ContentResponse = styled.div`
  display: flex;
`;

const Poll = ({ data }) => {
  const ContainerPolls = styled.div`
    display: flex;
    flex-direction: column;
  `;

  return (
    <ContainerPolls>
      <Text type="h3" color="primary" bold>
        Votações
      </Text>

      <Space mt={2} />

      <Content>
        {data.map(poll => (
          <Item>
            <Text type="h3" size="18" color="primary" bold>
              {poll?.poll?.name}
            </Text>

            <Space mt={1} />

            <Text type="p" size="16" color="black">
              {poll?.poll?.description}
            </Text>

            <Space mt={1} />

            <Text type="p" size="16" color="black" bold>
              Respostas:
            </Text>

            <Space mt={1} />

            {poll?.response?.map((response, key) => (
              <ContentResponse key={response._id}>
                <Text type="p" size="16" color="black" bold>
                  {(response.total / poll.total) * 100}% Responderam
                </Text>
                <Space ml={1} />
                <Text type="p" size="16" color="black">
                  {response.label}
                </Text>
              </ContentResponse>
            ))}
          </Item>
        ))}
      </Content>
    </ContainerPolls>
  );
};

const Quiz = ({ data }) => {
  const ContainerQuizes = styled.div`
    display: flex;
    flex-direction: column;
  `;

  return (
    <ContainerQuizes>
      <Text type="h3" color="primary" bold>
        Enquetes
      </Text>

      <Space mt={2} />

      <Content>
        {data.map(quiz => (
          <Item>
            <Text type="h3" size="18" color="primary" bold>
              {quiz?.quiz?.name}
            </Text>

            <Space mt={1} />

            <Text type="p" size="16" color="black">
              {quiz?.quiz?.description}
            </Text>

            <Space mt={1} />

            <Text type="p" size="16" color="black" bold>
              Respostas:
            </Text>

            <Space mt={1} />

            {quiz.response.map((response, key) => (
              <ContentResponse key={response._id}>
                <Text type="p" size="16" color="black" bold>
                  {(response.total / quiz.total) * 100}% Responderam
                </Text>
                <Space ml={1} />
                <Text type="p" size="16" color="black">
                  {response.label}
                </Text>
              </ContentResponse>
            ))}
          </Item>
        ))}
      </Content>
    </ContainerQuizes>
  );
};

export const Interactions = () => {
  const location = useLocation();
  const query = getQuery(location.search);

  const [dataPolls, { loading: loadingPolls }] = useService(
    'get',
    '/dashboard/viewers-polls',
    { room: query.room },
    true,
    [query.room]
  );

  const [dataQuizzes, { loading: loadingQuizzes }] = useService(
    'get',
    '/dashboard/viewers-quizzes',
    { room: query.room },
    true,
    [query.room]
  );

  return (
    <>
      <Container>
        <Loader show={loadingPolls}>
          <Poll data={dataPolls?.success?.polls || []} />
        </Loader>
      </Container>

      <Container>
        <Loader show={loadingQuizzes}>
          <Quiz data={dataQuizzes?.success?.quizzes || []} />
        </Loader>
      </Container>
    </>
  );
};
