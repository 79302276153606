import styled from 'styled-components';
import { Button, TableList, Loader } from '@ederzadravec/jade-ui';

import { Text } from 'components';
import { useService } from 'service';
import { dbToDate } from 'utils/date';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const LinkAction = styled(Text)`
  margin-right: 8px;
`;

export const SetStatusOn = ({ refresh, data, route }) => {
  const [, { loading }, setStatusItem] = useService('patch', route, {}, false);

  const handleOnSet = async () => {
    await setStatusItem();

    refresh();
  };

  return (
    <LinkAction
      type="link"
      bold
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        handleOnSet();
      }}>
      {loading ? '...' : data.isOnline ? 'Set/OFF' : 'Set/ON'}
    </LinkAction>
  );
};

export const SetStatusOff = ({ refresh, data, route }) => {
  const [, { loading }, setStatusItem] = useService('patch', route, {}, false);

  const handleOnSet = async () => {
    await setStatusItem();

    refresh();
  };

  return (
    <LinkAction
      type="link"
      bold
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        handleOnSet();
      }}>
      {loading ? '...' : data.isClosed ? 'Re Abrir' : 'Encerrar Sala'}
    </LinkAction>
  );
};

export default ({ history, location }) => {
  const [roomsData, { loading }, refreshRooms] = useService('get', '/room/today?limit=100');

  const BASE_URL = '/admin/in-live';

  const configList = [
    {
      name: 'Inicio',
      dataPath: data => dbToDate(data.openAt, 'HH:mm'),
      size: {
        md: 2,
      },
    },
    {
      name: 'Sala',
      dataPath: 'name.pt',
      size: {
        md: 6,
      },
    },
    {
      name: 'Status',
      dataPath: data => {
        return <Text>{data.isOnline ? 'ON' : 'OFF'}</Text>;
      },
      size: {
        md: 1,
      },
    },
    {
      name: 'Ação',
      dataPath: data => {
        return (
          <>
            <SetStatusOn
              data={data}
              refresh={refreshRooms}
              route={`/room/${data._id}/set-is-online`}
            />

            <SetStatusOff
              data={data}
              refresh={refreshRooms}
              route={`/room/${data._id}/set-is-closed`}
            />
          </>
        );
      },
      size: {
        md: 2,
      },
    },
  ];

  const handleOnSelectItem = item => {
    history.push(`${BASE_URL}/${item._id}`);
  };

  return (
    <Container>
      <Header>
        <Text type="h3" size="24" bold color="black">
          Salas ao Vivo
        </Text>

        <Button onClick={refreshRooms}>Atualizar</Button>
      </Header>

      <Loader show={loading}>
        <Content>
          <TableList
            config={configList}
            data={roomsData?.success?.rooms?.data || []}
            onSelect={handleOnSelectItem}
          />
        </Content>
      </Loader>
    </Container>
  );
};
