import React from 'react';
import styled from 'styled-components';

import { Text } from '../Text/Text';

const Container = styled.div`
  margin-top: 16px;
`;

const Content = styled.div`
  width: 100%;
  height: 50px;
  background-color: #fff;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
  margin: 8px 0;
  position: relative;
  overflow: hidden;
`;

const Label = styled(Text)`
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.palette.black.main};
`;

const Percent = styled(Text)`
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(-50%, -50%);

  ${({ value, theme }) =>
    value >= 25 ? `color: ${theme.palette.white.main}` : `color: ${theme.palette.primary.dark}`}
`;

const Progress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: ${({ value }) => (value ? value : 0)}%;
  background: ${({ theme }) => theme.palette.primary.main};
`;

export const ProgressBar = ({ value, label }) => {
  return (
    <Container>
      <Label value={value} size="16" semiBold color="primary" type="span">
        {label}
      </Label>
      <Content>
        <Progress value={value} />
        <Percent value={value} size="16" bold color="primary" type="span">
          {value}%
        </Percent>
      </Content>
    </Container>
  );
};
