import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@ederzadravec/jade-ui';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';

import { Space, Text, InputText, InputPassword, ErrorForm } from 'components';
import { useService } from 'service';
import { Store } from 'contexts';
import { ContextLanguage } from 'contexts';
import { formLogin } from './Texts';

import { Container, ContainerButton } from './style';

export const FormLogin = ({ setAction }) => {
  const { setSession, setStore } = React.useContext(Store.Context);
  const [data, setData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');

  const history = useHistory();

  const cxtLang = useContext(ContextLanguage);

  useEffect(() => {
    cxtLang.setTexts(formLogin);
  }, []);

  const [, { loading: validatingLogin }, login] = useService('post', `app/login`, null, false);

  const handleOnSubmit = async () => {
    const formData = {
      email: data.email.toLowerCase().replace(' ', ''),
      password: data.password,
    };

    const result = await login(formData);

    if (result?.success?.status === 'OK') {
      setStore({
        user: result.success.user,
      });

      setSession({
        session: result.success.token,
        type: result.success.user.type,
      });

      return history.push('/app');
    }

    if (result?.error) {
      setError(result?.error?.password);
      console.log('eee', result?.error.password);
    }
  };

  return (
    <Container>
      <Text type="h3" size="16" color="black" center bold>
        {cxtLang.texts[cxtLang.lang].title}
      </Text>

      <Space mt={4} />

      <InputText
        label={cxtLang.texts[cxtLang.lang].labelFieldEmail}
        name="email"
        type="text"
        value={data.email}
        onChange={e => setData({ ...data, email: e.target.value })}
      />

      <Space mt={4} />

      <InputPassword
        label={cxtLang.texts[cxtLang.lang].labelFieldPassword}
        name="password"
        value={data.password}
        onChange={e => setData({ ...data, password: e.target.value })}
      />

      {error && <ErrorForm content={error} />}

      <Space mt={1} />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <Text color="black" link bold onClick={() => setAction('register')}>
          1º ACESSO
        </Text> */}

        <Text color="black" link bold onClick={() => setAction('recover')}>
          {cxtLang.texts[cxtLang.lang].textForgetPassword}
        </Text>

        <Text
          color="black"
          link
          bold
          onClick={() =>
            Swal.fire({
              icon: 'question',
              title: 'Podemos ajudar...',
              text: 'Contate Gabriel via WhatsApp: +55 11 95463-1471',
            })
          }>
          Precisa de ajuda?
        </Text>
      </div>

      <Space mt={2} />

      <ContainerButton>
        <Button
          style={{ minWidth: '100%' }}
          color="primary"
          fontWeight={600}
          loading={validatingLogin}
          onClick={() => handleOnSubmit()}
          center>
          {cxtLang.texts[cxtLang.lang].labelButton}
        </Button>
      </ContainerButton>
    </Container>
  );
};
