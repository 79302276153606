import React from 'react';
import styled from 'styled-components';

import { Text } from '../Text/Text';
import { Modal } from '../Modal/Modal';
import { AdminLogRoom } from './AdminLogRoom';

const Container = styled.div`
  max-width: 280px;
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  margin-right: 12px;
  margin-bottom: 16px;
`;

const ContainerImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  background-size: cover;
  border-radius: 14px 14px 0 0;
  cursor: pointer;
`;

const Content = styled.div`
  width: 100%;
  padding: 24px;
`;

const Title = styled(Text)``;

const Speaker = styled(Text)`
  padding: 8px 0 0;
`;

const Schedule = styled(Text)`
  margin: 4px 0;
`;

export const CardRoomEasy = () => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <Container>
      <ContainerImage onClick={() => setShowModal(!showModal)} />

      <Content>
        <Title type="h1" size="20" color="black" bold>
          Lorem
        </Title>

        <Speaker type="h3" size="16" color="black" bold>
          Diego Z.Freitas
        </Speaker>

        <Schedule type="p" size="14" color="black">
          16 de Janeiro - 10h00 às 12h30
        </Schedule>
      </Content>

      {showModal && (
        <Modal size={{ md: 8 }} onClose={() => setShowModal(!showModal)}>
          <AdminLogRoom />
        </Modal>
      )}
    </Container>
  );
};
