import styled from 'styled-components';
import { FaTrophy } from 'react-icons/fa';
import ImageAvatar from 'assets/images/avatarImage.png';

import { Text, Space } from 'components';

const Container = styled.div`
  display: flex;
  margin-top: 36px;
  margin-bottom: 36px;
  width: 33%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const ContentAvatar = styled.div`
  position: relative;
  margin-right: 16px;
`;

const Avatar = styled.img`
  width: 105px;
  height: 105px;
  border-radius: 8px;
`;

const IconTrophy = styled(FaTrophy)`
  position: absolute;
  bottom: 4px;
  right: 0;
  border-radius: 8px;

  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.white.main};
  font-size: 30px;
  padding: 5px;
`;

const ContentInfo = styled.div``;

export const CardBestSale = ({ data }) => {
  return (
    <Container>
      <ContentAvatar>
        <Avatar src={data?.photo ? data?.photo : ImageAvatar} />

        <IconTrophy />
      </ContentAvatar>

      <ContentInfo>
        <Text type="h2" size="16" bold color="black">
          {data.name}
        </Text>
        <Text type="h2" size="14" color="black">
          {data.companyPosition ? data.companyPosition : 'não preenchido'}
        </Text>

        <Space mt={3} />

        <Text type="h2" size="18" bold color="black">
          {data.companyUnit ? data.companyUnit : 'não preenchido'}
        </Text>
      </ContentInfo>
    </Container>
  );
};
