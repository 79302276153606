import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import LogoSVG from 'assets/images/brand.svg';
import { Store } from 'contexts';
import { Text } from '../Text/Text';

import { ContextLanguage } from 'contexts';
import { homeTexts } from './Texts/HomeTexts';

import { Notifications } from './notifications/Notifications';
import { Search } from './Search';
import { Flags } from './Flags';

const Container = styled.div`
  width: 100%;
  height: 130px;
  background: ${({ theme }) => theme.palette.white.main};
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 230px;
  }
`;

const Header = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
`;

const Logo = styled.img`
  height: 60px;
  width: 160px;
  margin-right: 40px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 0;
    margin-right: unset;
  }
`;

const ContaintToggle = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Toggle = styled(FaBars)`
  font-size: 40px;
  color: ${({ theme }) => theme.palette.black.main};
  cursor: pointer;
`;

const LeftHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 32px;
    justify-content: space-between;
  }
`;

const CenterHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 32px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 32px;
  }
`;

const RightHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 32px 0 0;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 32px;
    justify-content: space-between;
  }
`;

const Wellcome = styled.div``;

const Separator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.black.main};
  width: 1px;
  height: 40px;
  margin-left: 16px;
  margin-right: 16px;
`;

const ImageProfile = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`;

export const Menu = ({ setSideActive }) => {
  const history = useHistory();
  const { user } = React.useContext(Store.Context);

  const cxtLang = useContext(ContextLanguage);

  useEffect(() => {
    cxtLang.setTexts(homeTexts);
  }, []);

  return (
    <Container>
      <Header>
        <LeftHeader>
          <ContaintToggle onClick={setSideActive}>
            <Toggle />
          </ContaintToggle>

          <Logo onClick={() => history.push('/app')} src={LogoSVG} />
        </LeftHeader>

        <CenterHeader>
          <Wellcome>
            <Text size="22" color="secondary" bold>
              <Text size="22" color="black">
                {cxtLang.texts[cxtLang.lang].navBarTextHI}
              </Text>
              {user?.name}
            </Text>
          </Wellcome>
        </CenterHeader>

        <RightHeader>
          {/* <Search /> */}

          <Flags />

          <Separator />

          {/* <Notifications />

          <Separator /> */}

          <ImageProfile src={user?.photo} />
        </RightHeader>
      </Header>
    </Container>
  );
};
