import styled from 'styled-components';

import { useService } from 'service';

const Container = styled.div`
  background-color: #fff;
  border-radius: 14px;
  margin-bottom: 24px;
  width: 100%;
`;

const ImageMobile = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    border-radius: 14px;
    height: auto;
    width: 100%;
    cursor: pointer;
  }
`;

const ImageDesk = styled.img`
  display: block;
  border-radius: 14px;
  height: auto;
  width: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BannerSponsorBig = () => {
  const [dataBanner, { loading }, fetchBanner] = useService('get', `/banner/big`);

  if (dataBanner?.error) return;

  const banner = dataBanner?.success?.banner;

  console.log('eee', banner);

  const handleOnClick = route => {
    window.open(route, '_blank');
  };

  return (
    <Container background={''}>
      <ImageMobile
        src={banner?.imageMobile}
        onClick={() => (banner?.link === '#' ? '' : handleOnClick(banner?.link))}
      />
      <ImageDesk
        src={banner?.image}
        onClick={() => (banner?.link === '#' ? '' : handleOnClick(banner?.link))}
      />
    </Container>
  );
};
