import styled from 'styled-components';
import ExportExcel from 'react-export-excel';
import { Loader, TableList, Button } from '@ederzadravec/jade-ui';

import { Text } from 'components';
import { useService } from 'service';

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelSheet;
const ExcelColumn = ExportExcel.ExcelColumn;

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const ResumeRoom = ({ idRoom }) => {
  const [dataActivity, { loading }, fetchAtcivity] = useService(
    'get',
    `/room/${idRoom}/viewers-activity`,
    {},
    true
  );

  const resume = dataActivity?.success?.viewers || [];

  const dataForExcel = resume?.map(item => {
    const time = `${item.time.hours
      .toString()
      .padStart(2, '0')}:${item.time.minutes
      .toString()
      .padStart(2, '0')}:${item.time.seconds.toString().padStart(2, '0')}`;

    return {
      viewer: item?.viewer?.name,
      quizzes: item?.quizzes,
      polls: item?.polls,
      questions: item?.questions,
      time: time,
    };
  });

  console.log('euu', { dataForExcel });

  const listConfig = [
    {
      name: 'Nome',
      size: { md: 4 },
      dataPath: 'viewer.name',
    },
    {
      name: 'Quizzes',
      size: { md: 2 },
      dataPath: 'quizzes',
    },
    {
      name: 'Votações',
      size: { md: 2 },
      dataPath: 'polls',
    },
    {
      name: 'Perguntas',
      size: { md: 2 },
      dataPath: 'questions',
    },
    {
      name: 'Tempo',
      size: { md: 2 },
      dataPath: data => (
        <Text>
          {data.time.hours.toString().padStart(2, '0')} :{' '}
          {data.time.minutes.toString().padStart(2, '0')} :{' '}
          {data.time.seconds.toString().padStart(2, '0')}
        </Text>
      ),
    },
  ];

  return (
    <Container>
      <Header>
        <Text size="22" color="black">
          Resumo da Sala
        </Text>

        <ExcelFile filename="Resumo da Sala" element={<Button>Download Data</Button>}>
          <ExcelSheet data={dataForExcel} name="sala">
            <ExcelColumn label="Usuarios" value="viewer"></ExcelColumn>
            <ExcelColumn label="Quizzes" value="quizzes"></ExcelColumn>
            <ExcelColumn label="Votações" value="polls"></ExcelColumn>
            <ExcelColumn label="Perguntas" value="questions"></ExcelColumn>
            <ExcelColumn label="Tempo" value="time"></ExcelColumn>
          </ExcelSheet>
        </ExcelFile>

        <Button loading={loading} onClick={() => fetchAtcivity()}>
          Atualizar
        </Button>
      </Header>
      <Loader show={loading} direction="column">
        <TableList config={listConfig} data={resume} />
      </Loader>
    </Container>
  );
};
