import React, { useState, useContext, useMemo } from 'react';
import { Space } from '@ederzadravec/jade-ui';
import styled from 'styled-components';
import { Box, Text } from 'components';
import { useNotificationContext } from 'contexts';

import { useService } from 'service';

import { Filters, List, Pagination } from './components';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const NoData = () => {
  return (
    <>
      <Space top={2} />
      <Text size={16}>Sem dados</Text>
    </>
  );
};

export default () => {
  const { handleReq } = useNotificationContext();

  const cxtLang = useContext(ContextLanguage);

  React.useEffect(() => {
    handleReq();
  }, []);

  const [refresh, setRefresh] = useState('');
  const [paginationSettings, setPaginationSettings] = useState('');
  const [filter, setFilter] = useState({
    category: '',
    order: 'asc',
    search: '',
  });

  useMemo(() => {
    if (filter.search.length > 2) {
      setRefresh(new Date());
    }

    if (refresh !== '' && filter.search.length === 0) {
      setFilter({ ...filter, search: '' });
      setRefresh(new Date());
    }
  }, [filter.search]);

  const [dataReq, { loading }] = useService(
    'get',
    `https://next-frontiers-api-iim9q.ondigitalocean.app/resume/public-get-all-resumes?${paginationSettings}&order=${filter.order}&category=${filter.category}&search=${filter.search}`,
    {},
    true,
    [paginationSettings, filter.category, filter.order, refresh]
  );

  return (
    <Container>
      <Box>
        <Text type="h2" size="22" color="primary" bold>
          {cxtLang.lang === 'pt' && 'Resumos Enviados'}
          {cxtLang.lang === 'en' && 'Submitted Abstracts'}
          {cxtLang.lang === 'es' && 'Resúmenes enviados'}
        </Text>

        <Filters data={dataReq} filter={filter} setFilter={setFilter} />

        {loading ? (
          `${
            (cxtLang.lang === 'pt' && 'Carregando...') ||
            (cxtLang.lang === 'en' && 'Loading ...') ||
            (cxtLang.lang === 'es' && 'Cargando...')
          }`
        ) : dataReq?.resumes?.data.length <= 0 ? (
          <NoData />
        ) : (
          <List data={dataReq?.resumes?.data} />
        )}

        <Pagination
          noInfos
          data={dataReq?.resumes?.pagination}
          returnS={paginationSettings}
          setReturnS={setPaginationSettings}
        />
      </Box>
    </Container>
  );
};
