import styled from 'styled-components';
import { Button, TableList, Loader } from '@ederzadravec/jade-ui';
import { Switch, Route } from 'react-router-dom';

import { Text } from 'components';
import { useService } from 'service';
import { dbToDate } from 'utils/date';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export default ({ history, location }) => {
  const [roomsData, { loading }, refreshSpeakers] = useService('get', '/room?limit=100');

  const BASE_URL = '/admin/room';

  const configList = [
    {
      name: 'Data',
      dataPath: data => dbToDate(data.openAt),
      size: {
        md: 2,
      },
    },
    {
      name: 'Sala',
      dataPath: 'name.pt',
      size: {
        md: 3,
      },
    },
    {
      name: 'Abertura',
      dataPath: data => dbToDate(data.openAt, 'dd/MM/yyyy - HH:mm'),
      size: {
        md: 2,
      },
    },
    {
      name: 'Fechamento',
      dataPath: data => dbToDate(data.closeAt, 'dd/MM/yyyy - HH:mm'),
      size: {
        md: 2,
      },
    },
    {
      name: 'Palestras',
      dataPath: data => data?.lectures?.length || 0,
      size: {
        md: 1,
      },
    },
    {
      name: 'Ação',
      dataPath: data => (
        <Text
          type="link"
          size="16"
          bold
          color="black"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            history.push(`${BASE_URL}/edit/${data._id}`);
          }}>
          Editar
        </Text>
      ),
      size: {
        md: 2,
      },
    },
  ];

  const handleOnSelectItem = item => {
    history.push(`${BASE_URL}/details/${item._id}/talk`);
  };

  const handleFormClose = () => {
    history.push(BASE_URL);
    refreshSpeakers();
  };

  return (
    <Container>
      <Header>
        <Text type="h3" size="24" bold color="black">
          Salas
        </Text>

        <Button
          onClick={() => {
            history.push(`${BASE_URL}/new`);
          }}>
          Nova
        </Button>
      </Header>

      <Loader show={loading}>
        <Content>
          <TableList
            config={configList}
            data={roomsData?.success?.rooms?.data || []}
            onSelect={handleOnSelectItem}
          />
        </Content>
      </Loader>

      <Switch>
        <Route
          path={[`${BASE_URL}/new`, `${BASE_URL}/:room`]}
          component={props => <Form {...props} onClose={handleFormClose} />}
        />
      </Switch>
    </Container>
  );
};
