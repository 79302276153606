export const registerFull = {
  es: {
    titleForm: 'Registro: nuevo usuario',

    formSectionPersonal: 'Datos Personales',
    labelName: 'Nombre',
    labelEmail: 'E-mail',
    labelUnity: 'Unidad',
    labelOffice: 'Oficina',

    textAddImage: 'Seleccione su foto: (requerido)',
    textPassword: 'Contraseña y seguridad',
    labelPassword: 'Contraseña',
    labelConfirmationPassword: 'Confirmación de contraseña',
    textTermsUse: 'Soy consciente y acepto la ',
    textUriTermsUse: 'Terminos de uso',
    uriTermUser: 'https://convencao2021.totvs.com/termos-de-uso.pdf',
    buttonAccess: '1er Acceso',
    titleFooter: 'Desarrollado por BTO + Comunicação y Deploy. Agencia Futura',
  },
  en: {
    titleForm: 'Registration - New User',

    formSectionPersonal: 'Personal data',
    labelName: 'Name',
    labelEmail: 'E-mail',
    labelUnity: 'Unity',
    labelOffice: 'office',

    textAddImage: 'Select your photo: (required)',
    textPassword: 'Password and Security',
    labelPassword: 'Password',
    labelConfirmationPassword: 'Password Confirmation',
    textTermsUse: 'I am aware and accept the ',
    textUriTermsUse: 'terms of use',
    uriTermUser: 'https://convencao2021.totvs.com/termos-de-uso.pdf',
    buttonAccess: '1st Access',
    titleFooter: 'Developed by BTO + Comunicação and Deploy. Future Agency',
  },
  pt: {
    titleForm: 'Cadastro - Novo Usuário',

    formSectionPersonal: 'Dados Pessoais',
    labelName: 'Nome',
    labelEmail: 'E-mail',
    labelUnity: 'Unidade',
    labelOffice: 'Cargo',

    textAddImage: 'Selecione sua foto: (obrigatório)',
    textPassword: 'Senha e Segurança',
    labelPassword: 'Senha',
    labelConfirmationPassword: 'Confirmação de Senha',
    textTermsUse: 'Estou ciente e aceito os ',
    textUriTermsUse: 'termos de uso',
    uriTermUser: 'https://convencao2021.totvs.com/termos-de-uso.pdf',
    buttonAccess: '1º Acesso',
    titleFooter: 'Desenvolvido por BTO+ Comunicação e Deploy. Digital Business',
  },
};
