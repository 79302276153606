import styled from 'styled-components';
import { Grid } from '@ederzadravec/jade-ui';

import { Wall } from './Wall';
import { Instagram } from './Instagram';

export const WallInstaSection = () => {
  return (
    <Grid container>
      <Grid size={{ md: 9, xs: 12 }}>
        <Instagram />
      </Grid>
      <Grid size={{ md: 3, xs: 12 }}>
        <Wall />
      </Grid>
    </Grid>
  );
};
