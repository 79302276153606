import { Container, Content, Checker } from './checkbox.style';

import { Text } from '../../Text/Text';

export const Checkbox = ({ key, checked, onClick, label, table, ...props }) => {
  return (
    <Container {...props} table={table} onClick={onClick}>
      <Content checked={checked} onClick={onClick} label={label} key={key}>
        <Checker checked={checked} />
      </Content>

      {label && <Text>{label}</Text>}
    </Container>
  );
};
Checkbox.defaultProps = {
  checked: false,
  onClick: () => {},
};
