import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormGrid, hooks, TextInput, Button, Loader } from '@ederzadravec/jade-ui';
import { FaTrophy } from 'react-icons/fa';
import Swal from 'sweetalert2';

import AvatarImg from 'assets/images/avatarImage.png';
import { Box, Text } from 'components';
import { useService } from 'service';
import { dbToDate } from 'utils/date';

import { ContextLanguage } from 'contexts';

const Container = styled(Box)`
  width: 100%;
`;

const ContentWalls = styled(Loader)`
  margin: 24px 0;
  max-height: 400px;
  overflow: auto;
`;

const validations = {
  content: [[value => !value, 'Deve ser preenchido']],
};

const Message = styled.div`
  margin: 8px 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black.light};
  width: 100%;
`;

const HeaderMessage = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const ContentAvatar = styled.div`
  position: relative;
`;

const Avatar = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 10px;
`;

const IconTrophy = styled(FaTrophy)`
  position: absolute;
  bottom: 0;
  right: 6px;
  border-radius: 4px;
  background: #ff9d2f;
  color: #ffffff;
  font-size: 22px;
  padding: 3px;

  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.white.main};
`;

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NameUser = styled(Text)`
  font-weight: 700;
`;

const TextMessage = styled(Text)`
  margin-bottom: 8px;
  width: 100%;
`;

export const Wall = () => {
  const [form, onChange] = hooks.useForm({ validations });
  const [dataWalls, { loading }, fetchWalls] = useService('get', `/wall/walls-publics`, {}, true);
  const [, { loading: loadingSendWall }, sendWall] = useService('post', `/wall`, {}, false);

  const cxtLang = useContext(ContextLanguage);

  const handleOnSubmit = async () => {
    const data = {
      content: form.getValue('content'),
    };

    const result = await sendWall(data);

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: `${
          (cxtLang.lang === 'pt' && 'Sua publicação foi enviada para moderação') ||
          (cxtLang.lang === 'en' && 'Your post has been sent for moderation') ||
          (cxtLang.lang === 'es' && 'Tu publicación ha sido enviada para moderación')
        }`,
        confirmButtonText: 'Ok',
      });
      form.clear();
    }

    if (result.error) {
      return form.setError(result.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'content',
        size: { md: 12 },
        type: TextInput,
        props: schema => ({
          label: `${
            (cxtLang.lang === 'pt' && 'Mensagem') ||
            (cxtLang.lang === 'en' && 'Message') ||
            (cxtLang.lang === 'es' && 'Mensaje')
          }`,
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text size="22" type="h2" bold color="black">
        {cxtLang.lang === 'pt' && 'PUBLICAÇÕES'}
        {cxtLang.lang === 'en' && 'FEED'}
        {cxtLang.lang === 'es' && 'PUBLICACIONES'}
      </Text>

      <ContentWalls direction="column" show={loading}>
        {dataWalls?.success?.walls?.data.map(wall => (
          <Message key={wall._id}>
            <HeaderMessage>
              <ContentAvatar>
                {wall.viewer && <Avatar src={wall.viewer.photo} />}
                {wall.user && <Avatar src={AvatarImg} />}

                {wall.viewer.isDetach && <IconTrophy />}
              </ContentAvatar>

              <ContentInfo>
                <NameUser size="18" semiBold type="h3" color="primary">
                  {wall.user && wall.user.name + ' ADM'}
                  {wall.viewer && wall.viewer.name}
                </NameUser>

                <Text size="12" semiBold type="p" color="primary">
                  em: {dbToDate(wall.createdAt, 'dd/MM - HH:mm')}
                </Text>
              </ContentInfo>
            </HeaderMessage>

            <TextMessage type="p" color="black" size="14">
              {wall.content}
            </TextMessage>
          </Message>
        ))}
      </ContentWalls>

      <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
        <Button loading={loadingSendWall} color="secondary">
          {cxtLang.lang === 'pt' && 'ENVIAR'}
          {cxtLang.lang === 'en' && 'SEND'}
          {cxtLang.lang === 'es' && 'ENVIAR'}
        </Button>
      </FormGrid>
    </Container>
  );
};
