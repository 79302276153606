import styled from 'styled-components';

const Container = styled.div`
  overflow: hidden;
  flex: 1;
`;

const ContainerVideo = styled.div`
  position: relative;
  padding: 50% 0 0 0;
  border: 0;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-color: unset;
  border-radius: 14px 14px 0 0;
  overflow: hidden;
`;

export const VideoEnglish = ({ link }) => {
  return (
    <Container>
      <ContainerVideo>
        {link && (
          <Iframe src={`https://player.vimeo.com/video/${link}`} allow="fullscreen;"></Iframe>
        )}
      </ContainerVideo>
    </Container>
  );
};
