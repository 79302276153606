import React, { useContext } from 'react';
import styled from 'styled-components';
import { BoxInput, Button as JDButton, Loader } from '@ederzadravec/jade-ui';

import { Text } from 'components';
import { Room } from 'contexts';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  padding: 24px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Question = styled(Text)`
  margin-top: 16px;
`;

const Options = styled.div`
  margin-top: 24px;
`;

const Option = styled(BoxInput)`
  min-height: auto;
  margin-bottom: unset;
  border-radius: 4px;
  padding: 5px;

  ${({ correct }) => (correct ? 'background:#dfffb4 ;' : 'background: #ffa9a9;')}
  ${({ notResponse }) => !notResponse && 'background:#fff;'}
`;

const Button = styled(JDButton)`
  min-width: 100%;
  display: block;
  margin-bottom: 8px;
`;

export const Quiz = ({ idRoom }) => {
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const { quiz: data, sendQuiz } = React.useContext(Room.Context);

  const quiz = data?.quiz || {};
  const userResponse = data?.userResponse;
  const correctResponse = data?.correctResponse;

  const cxtLang = useContext(ContextLanguage);

  React.useEffect(() => {
    setLoading(false);
  }, [data]);

  const handleOnSubmit = async () => {
    setLoading(true);

    const data = {
      quiz: quiz._id,
      response: value,
    };

    await sendQuiz(data);
  };

  return (
    <Container>
      <Text size="24" type="h3" color="primary" bold>
        {cxtLang.lang === 'pt' && 'QUIZ'}
        {cxtLang.lang === 'en' && 'QUIZ '}
        {cxtLang.lang === 'es' && 'QUIZ'}
      </Text>

      <Question size="14" type="h3" color="black">
        {quiz.description[cxtLang.lang]}
      </Question>

      <Loader show={loading} direction="column">
        <Options>
          {quiz.alternatives
            .filter(item => item.label[cxtLang.lang])
            .map(item => (
              <Option
                disabled={userResponse}
                key={item._id}
                value={item._id === value || userResponse?.response === item._id}
                label={`${item.label[cxtLang.lang]} ${
                  correctResponse?._id === item._id ? '(correta)' : ''
                }`}
                onChange={() => setValue(item._id)}
                correct={correctResponse?._id === item._id}
                notResponse={correctResponse?._id}
              />
            ))}
        </Options>

        {!userResponse && (
          <Button disabled={!value || loading} onClick={handleOnSubmit}>
            {cxtLang.lang === 'pt' && 'Confirmar'}
            {cxtLang.lang === 'en' && 'Confirm '}
            {cxtLang.lang === 'es' && 'Confirmar'}
          </Button>
        )}
      </Loader>
    </Container>
  );
};
