import React from 'react';
import styled from 'styled-components';
import { Loader } from '@ederzadravec/jade-ui';

import { Box, Text, Space, Pagination } from 'components';
import { CardBestSale } from './CardBestSale';
import { useService } from 'service';
import { getQuery } from 'utils/url';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ListBests = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export default ({ history }) => {
  const [viewersData, { loading }, refreshViewers] = useService(
    'get',
    '/viewer/detach',
    {
      page: getQuery(location.search)?.page || 1,
      limit: 250,
    },
    true,
    [location.search]
  );

  const handleOnPaginate = page => {
    history.push({ search: `page=${page}` });
  };

  return (
    <Container>
      <Box>
        <Text type="h3" size="24" bold color="secondary">
          PALESTRANTES
        </Text>

        <Space mt={3} />

        <Text type="p" size="16" color="black">
        Veja aqui quem são nossos palestrantes
        </Text>

        <Loader direction="column" show={loading}>
          <ListBests>
            {viewersData?.success?.viewer?.data.map((viewer, key) => (
              <CardBestSale key={key} data={viewer} />
            ))}
          </ListBests>
        </Loader>

        <Pagination
          total={viewersData?.success?.viewer?.total?.total}
          page={viewersData?.success?.viewer?.page}
          limit={viewersData?.success?.viewer?.limit}
          onChange={handleOnPaginate}
        />
      </Box>
      <br />
    </Container>
  );
};
