import React, { useContext } from 'react';
import { Button } from '@ederzadravec/jade-ui';
import { FaDownload } from 'react-icons/fa';
import { Container, Content } from './itemList.style';
import { Text } from 'components';

import { ContextLanguage } from 'contexts';

export const ItemList = ({ data }) => {
  const cxtLang = useContext(ContextLanguage);

  return (
    <Container>
      <Content>
        <Text size={20} type="h3" bold>
          {data?.id} - {data?.title}
        </Text>
        <Content>
          <Text color="grey" size={16}>
            {(cxtLang.lang === 'pt' && 'Categorias ') ||
              (cxtLang.lang === 'en' && 'Categorys ') ||
              (cxtLang.lang === 'es' && 'Categorías ')}
          </Text>
          <Text color="grey" size={16}>
            {data?.category}
          </Text>
        </Content>
        <Text color="grey" variant="dark" type="p" size={12}>
          {(cxtLang.lang === 'pt' && `Contato do responsável pelo envio do e-poster: ${data?.name} - ${data?.email}`) ||
            (cxtLang.lang === 'en' &&
            `Contact of the person responsible for sending the e-poster: ${data?.name} - ${data?.email}`) ||
            (cxtLang.lang === 'es' && `Contacto del responsable del envío del e-poster: ${data?.name} - ${data?.email}` )}
        </Text>
      </Content>

      <Button
        onClick={() => {
          window.open(data?.fileUrl);
        }}
        iconBefore={FaDownload}>
           {(cxtLang.lang === 'pt' && 'Baixar Poster') ||
            (cxtLang.lang === 'en' &&
              'Download Poster') ||
            (cxtLang.lang === 'es' && 'Descargar cartel')}
        
      </Button>
    </Container>
  );
};
