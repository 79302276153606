import React, { useContext, useEffect } from 'react';
import { Button } from '@ederzadravec/jade-ui';
import { useHistory } from 'react-router-dom';

import { Text, Space } from 'components';
import { ContextLanguage } from 'contexts';
import { wellcome } from './Texts';

import { Container, ContainerButton } from './style';

export const Wellcome = ({ setAction }) => {
  const history = useHistory();

  const cxtLang = useContext(ContextLanguage);

  useEffect(() => {
    cxtLang.setTexts(wellcome);
  }, []);

  return (
    <Container>
      <Text type="h3" size="16" color="black" center bold>
        {cxtLang.texts[cxtLang.lang].title}
      </Text>

      <Space mt={2} />

      <Text type="h3" size="16" color="black" center>
        {cxtLang.texts[cxtLang.lang].descripition}
      </Text>

      <Space mt={3} />

      <ContainerButton>
        <Button color="primary" onClick={() => setAction('register')}>
          {cxtLang.texts[cxtLang.lang].labelButtonRegister}
        </Button>

        <Space ml={1} />

        <Button color="primary" onClick={() => setAction('login')}>
          {cxtLang.texts[cxtLang.lang].labelButtonLogin}
        </Button>
      </ContainerButton>
    </Container>
  );
};
