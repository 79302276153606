import React, { useState } from 'react';
import styled from 'styled-components';
import { Loader } from '@ederzadravec/jade-ui';

import { ModalDetail } from './ModalDetail';
import { useService } from 'service';
import { CardRoom } from './CardRoom';
import { dbToDate, newDate } from 'utils/date';

const Container = styled.div`
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 768px) {
    padding: 4px;
    flex-wrap: wrap;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const GroupRooms = ({ date, ...props }) => {
  const [idModalShow, setIdModalShow] = useState('');
  const [dataRooms, { loading }, fetchRoom] = useService(
    'get',
    `app/room/?date=${date.date || ''}&limit=100`,
    {},
    true,
    [date]
  );

  const dateRooms = dataRooms?.success?.rooms?.data || [];

  return (
    <>
      <Container {...props}>
        <Loader show={loading}>
          <Content lengthRooms={dateRooms.length}>
            {dateRooms.map((data, key) => (
              <CardRoom key={key} data={data} setIdModalShow={setIdModalShow} />
            ))}
          </Content>
        </Loader>
      </Container>

      {idModalShow && <ModalDetail onClose={() => setIdModalShow('')} dataRoom={idModalShow} />}
    </>
  );
};
