import styled from 'styled-components';

import { Text as CpText } from 'components';

import ImageQrCode from 'assets/images/qrcode.svg';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  margin-bottom: 24px;
  padding: 24px;
  width: 100%;
  display: flex;
  height: fit-content;
  align-items: center;
`;

const Col = styled.div`
  width: 50%;
  padding: 16px;
`;

const Image = styled.img`
  width: 100%;
`;

const Text = styled(CpText)`
  line-height: 20px;
`;

export const QrCode = () => {
  return (
    <Container>
      <Col>
        <Text type="p">
          Mandar o Texto para colocar aqui !!!
        </Text>
      </Col>

      <Col>
        <Image src={ImageQrCode} />
      </Col>
    </Container>
  );
};
