import React, { useEffect } from 'react';
import { Select } from '@ederzadravec/jade-ui';
import styled from 'styled-components';
import { Button as JDButton, TableList, Loader } from '@ederzadravec/jade-ui';
import { Switch, Route } from 'react-router-dom';

import { Text, Search, Space } from 'components';
import { useService } from 'service';

import { Form } from './Form';
import { CompleteForm } from './CompleteForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const LinkAction = styled(Text)`
  margin-right: 8px;
`;

const ContentButton = styled.div`
  display: flex;
`;

const Button = styled(JDButton)`
  margin-left: 16px;
`;

export default ({ history, location }) => {
  const [loadingDetach, setLoadingDetach] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [userData, { loading }, refreshViewers] = useService('get', `/viewer?limit=100&page=${page}`, {}, true, [page]);
  const [, {}, fetchDetachViewer] = useService('patch', `viewer/setdetach`, {}, false);

  const handleOnDetach = async id => {
    setLoadingDetach(id);
    const result = await fetchDetachViewer({ id: id });

    if (result?.success?.status === 'OK') {
      refreshViewers();
      setLoadingDetach(null);
    }

    if (result?.error) {
      console.log('euu', 'deu ruimm');
    }
  };

  const BASE_URL = '/admin/viewer';

  const configList = [
    {
      name: 'Usuário',
      dataPath: 'name',
      size: {
        md: 3,
      },
    },
    {
      name: 'E-mail',
      dataPath: 'email',
      size: {
        md: 6,
      },
    },
    {
      name: 'Açao',
      dataPath: data => (
        <>
          <LinkAction
            type="link"
            color="black"
            onClick={() => history.push(BASE_URL + '/' + data._id)}>
            Editar
          </LinkAction>

          <LinkAction type="link" color="black" onClick={() => handleOnDetach(data._id)}>
            {loadingDetach === data._id && '...'}
            {!data.isDetach ? 'Destacar' : '**Detaque!'}
          </LinkAction>
        </>
      ),
      size: {
        md: 3,
      },
    },
  ];

  const totalPages = userData?.success?.viewer?.total?.total / userData?.success?.viewer?.limit;

  return (
    <Container>
      <Header>
        <Text type="h3" size="24" bold color="black">
          Usuário Público
        </Text>

        <ContentButton>
          <Search data={userData?.success?.viewer?.data || []} route="admin/viewer" />

          <Space mr={2} />

          {userData?.success?.viewer?.total?.total && (
            <select onChange={obj => console.log('eee', setPage(obj.target.value))}>
              {[...new Array(Math.round(totalPages))].map((itens, key) => (
                <option value={key + 1}>Página {key + 1}</option>
              ))}
            </select>
          )}

          <Button
            onClick={() => {
              history.push(`${BASE_URL}/complete-registration`);
            }}>
            Cadastro Completo
          </Button>

          <Button
            onClick={() => {
              history.push(`${BASE_URL}/new`);
            }}>
            Novo
          </Button>
        </ContentButton>
      </Header>

      <Loader show={loading}>
        <Content>
          <TableList config={configList} data={userData?.success?.viewer?.data || []} />
        </Content>
      </Loader>

      <Switch>
        <Route path={`${BASE_URL}/new`}>
          <Form
            onClose={() => {
              history.push(BASE_URL);
              refreshViewers();
            }}
          />
        </Route>

        <Route path={`${BASE_URL}/complete-registration`}>
          <CompleteForm
            onClose={() => {
              history.push(BASE_URL);
              refreshViewers();
            }}
          />
        </Route>
      </Switch>
    </Container>
  );
};
