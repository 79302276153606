import styled from 'styled-components';

export const Space = styled.div`
  margin-top: ${({ mt }) => mt * 8}px;
  margin-bottom: ${({ mb }) => mb * 8}px;
  margin-right: ${({ mr }) => mr * 8}px;
  margin-left: ${({ ml }) => ml * 8}px;
`;

Space.defaultProps = {
  mt: 0,
  mb: 0,
  ml: 0,
  mr: 0,
};
