import React from 'react';
import styled from 'styled-components';
import { hooks, FormGrid, TextInput, Button, Loader } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { Text, Space } from 'components';
import { useService } from 'service';

const Container = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const ContentButton = styled.div`
  width: 200px;
  display: inline-block;
  margin-right: 12px;
`;

const validations = {};

export const Form = ({ idUser }) => {
  const [, { loading }, fecthUser] = useService('get', `user/${idUser}`, {}, false);
  const [, { loading: updateLoading }, updateUser] = useService(
    'patch',
    `user/${idUser}`,
    {},
    false
  );

  const [form, onChange] = hooks.useForm({ validations });

  React.useEffect(() => {
    fecthUser().then(data => {
      if (data?.error) {
        console.log('euu', 'deu ruim');
      }

      if (data?.success?.status === 'OK') {
        const user = data?.success?.user;

        form.setValues(user);
      }
    });
  }, []);

  const handleOnSubmit = async () => {
    const data = {
      name: form.getValue('name'),
      email: form.getValue('email'),
      cpf: form.getValue('cpf'),
    };

    const result = await updateUser(data);

    if (result?.success?.status === 'OK') {
      Swal.fire({
        title: 'Atualizado',
        confirmButtonText: 'Ok',
      });
    }

    if (result.error) {
      return form.setError(result.error);
    }
  };

  const formConfig = [
    [
      {
        schema: 'name',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'Nome',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'email',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'E-mail',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },

      {
        schema: 'cpf',
        size: { md: 4, xs: 12 },
        type: TextInput,
        props: schema => ({
          label: 'CPF',
          value: form.getValue(schema),
          error: form.getError(schema),
          onChange: (e, value) => onChange(schema)(value),
        }),
      },
    ],
  ];

  return (
    <Container>
      <Text type="h2" size="22" color="primary" bold>
        Dados Pessoais
      </Text>

      <Space mt={2} />

      <hr />

      <Space mt={4} />

      <Loader show={loading}>
        <FormGrid config={formConfig} onSubmit={form.trySave(handleOnSubmit)}>
          <ContentButton>
            <Button loading={updateLoading} color="primary">
              Salvar
            </Button>
          </ContentButton>
        </FormGrid>
      </Loader>
    </Container>
  );
};
