import React from 'react';
import styled from 'styled-components';

import { CardRoom } from './CardRoom';
import { Text, Space } from 'components';

const Container = styled.div`
  background-size: cover;
  border-radius: 14px;
  background-color: #ffffff;
  width: 100%;
  padding: 24px 32px;
  margin-bottom: 24px;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    padding: 8px 16px;
    flex-wrap: wrap;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const GroupRooms = ({ data, refreshList }) => {
  return (
    <Container>
      {data.length === 0 && (
        <>
          <Space mt={2} />
          <Content>
            <Text type="h3" center color="primary" bold>
              Você não tem salas na agenda
            </Text>
          </Content>
          <Space mt={2} />
        </>
      )}
      <Space mt={2} />
      <Content>
        {data.map(item => (
          <CardRoom data={item.room} refreshList={refreshList} />
        ))}
      </Content>
    </Container>
  );
};
