import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { Text, Space } from 'components';
import LogoBrand from 'assets/images/brand.svg';
import BGPage from 'assets/images/bg_login.svg';

import { ContextLanguage } from 'contexts';
import { registerFull } from './Texts/RegisterFull';

import { Form } from './Form';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${BGPage});
  background-repeat: no-repeat;
`;

const Content = styled.div`
  min-width: 65%;
  background: #ffffff;
  border-radius: 14px;
  padding: 18px 20px;
`;

const ImageLogo = styled.img`
  display: block;
  width: 200px;
  margin: 16px 0;
`;

const SetLanguage = ({ setLang }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '20px',
        right: '20px',
        display: 'flex',
        background: 'white',
      }}>
      <span
        style={{ padding: '8px', cursor: 'pointer', margin: '5px' }}
        onClick={() => setLang('es')}>
        ES
      </span>
      <span
        style={{ padding: '8px', cursor: 'pointer', margin: '5px' }}
        onClick={() => setLang('en')}>
        EN
      </span>
      <span
        style={{ padding: '8px', cursor: 'pointer', margin: '5px' }}
        onClick={() => setLang('pt')}>
        PT
      </span>
    </div>
  );
};

export default () => {
  const cxtLang = useContext(ContextLanguage);

  useEffect(() => {
    cxtLang.setTexts(registerFull);
  }, []);

  return (
    <Container>
      <SetLanguage setLang={cxtLang.setLang} />
      <Content>
        <ImageLogo src={LogoBrand} alt="Logo" />

        <Text type="h3" color="primary" bold>
          {cxtLang.texts[cxtLang.lang].titleForm}
        </Text>

        <Form />
      </Content>
    </Container>
  );
};
