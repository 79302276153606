export const formRegister = {
  es: {
    title: 'Haz tu registro',
    name: 'Name',
    labelOffice: 'Oficina',
    labelFieldEmail: 'E-mail',

    labelPassword: 'Contraseña',
    labelConfirmationPassword: 'Confirmación de contraseña',

    labelButton: 'Registrar',
    
    uriBackToLogin: 'Volver a iniciar sesión',

  },
  en: {
    title: 'Make your registration',
    name: 'Name',
    labelOffice: 'office',
    labelFieldEmail: 'E-mail',

    labelPassword: 'Password',
    labelConfirmationPassword: 'Password Confirmation',

    labelButton: 'Register',

    uriBackToLogin: 'Back to login',
  },
  pt: {
    title: 'Faça seu cadastro',
    name: 'Nome',
    labelOffice: 'Cargo',
    labelFieldEmail: 'E-mail',

    labelPassword: 'Senha',
    labelConfirmationPassword: 'Confirmação de Senha',

    labelButton: 'Cadastrar',

    uriBackToLogin: 'Volte ao login',
  },
};
