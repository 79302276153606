import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button as JDButton, Loader } from '@ederzadravec/jade-ui';

import { Text, ProgressBar } from 'components';
import { Room } from 'contexts';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  padding: 24px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Question = styled(Text)`
  margin-top: 16px;
  min-height: auto;
`;

const Options = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  justify-content: space-between;
`;

const Button = styled(JDButton)`
  min-width: 100%;
  display: block;
  margin-bottom: 8px;
`;

const Resume = styled.div``;

export const Poll = ({ idRoom }) => {
  const [loading, setLoading] = React.useState(false);
  const { poll: data, sendPoll } = React.useContext(Room.Context);

  const poll = data?.poll || {};
  const userResponse = data?.userResponse;
  const percentualResponse = data?.percentualResponse;

  const cxtLang = useContext(ContextLanguage);

  React.useEffect(() => {
    setLoading(false);
  }, [data]);

  const handleOnSubmit = async value => {
    setLoading(true);

    const data = {
      poll: poll._id,
      response: value,
    };

    await sendPoll(data);
  };

  return (
    <Container>
      <Text size="24" type="h3" color="primary" bold>
        {cxtLang.lang === 'pt' && 'Votação'}
        {cxtLang.lang === 'en' && 'Voting'}
        {cxtLang.lang === 'es' && 'Votación'}
      </Text>

      <Question size="14" type="h3" color="black">
        {poll.description[cxtLang.lang]}
      </Question>

      {userResponse && (
        <Resume>
          {percentualResponse
            ?.filter(item => item.label[cxtLang.lang])
            ?.map(item => (
              <ProgressBar key={item._id} value={item.percent.toFixed(1)} label={item.label[cxtLang.lang]} />
            ))}
        </Resume>
      )}

      {!userResponse && (
        <Loader show={loading}>
          <Options>
            {poll.alternatives
              ?.filter(item => item.label[cxtLang.lang])
              ?.map(item => (
                <Button key={item._id} disabled={loading} onClick={() => handleOnSubmit(item._id)}>
                  {item.label[cxtLang.lang]}
                </Button>
              ))}
          </Options>
        </Loader>
      )}
    </Container>
  );
};
