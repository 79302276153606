import styled, { css } from 'styled-components'
import { FaEye } from 'react-icons/fa'
import RIInputMask from 'react-input-mask'

const defaultInput = css`
  background-color: ${({ theme, color }) =>
    color ? theme.palette[color]?.main : theme.palette.white.main};

  color: ${({ theme }) => theme.palette.primary.main};
  border: 1px solid  ${({ theme }) => theme.palette.black.light};
  padding: 14px 8px;
  width: ${({ widthInput }) => widthInput ? `${widthInput}%` : '100%'};
  border-radius: 10px;


  &::placeholder{
    color: ${({ theme }) => theme.palette.black.main};
  }


  ${({ disabled, theme }) => disabled ? `
    background: ${theme.palette.black.light};
    cursor: not-allowed;
    `: ''};

  ${({ theme, error }) => error ? `
    border: 1px solid  ${theme.palette.alert.main};
    ` : ''};
`;

export const Container = styled.div`
  margin: 10px 0;
  position: relative;
  width: 100%;
`;

export const InputMask = styled(RIInputMask)`
  ${defaultInput}
`;

export const Input = styled.input`
  ${defaultInput}
`;

export const Label = styled.span`
  transition: .1s;
  color: ${({ theme }) => theme.palette.black.main};

  padding: 4px 10px 4px 10px;
  text-align: center;
  position: absolute;
  z-index: 0;

  top: -20px;
  left: -8px;
  font-size: 12px;

  &:before{
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;

    transition: .2s;
  }
`;


export const LabelError = styled.span`
  /* background-color: ${({ theme, backgroundInput, focus }) => backgroundInput ? focus && backgroundInput : focus && theme.palette.white.main}; */
  color: ${({ theme }) => theme.palette.alert.main};
  font-size: 10px;
  position: absolute;
  padding: 4px;
  bottom: -20px;
  left: 12px;
`;

export const IconIsVisible = styled(FaEye)`
  color: ${({ theme }) => theme.palette.black.main};
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
`;
