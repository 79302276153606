import React from 'react';
import styled from 'styled-components';
import { Button, Loader } from '@ederzadravec/jade-ui';
import Swal from 'sweetalert2';

import { dbToDate } from 'utils/date';
import { useHistory } from 'react-router-dom';
import { Text } from 'components';
import { useService } from 'service';

const Container = styled.div`
  ${({ hidden }) => hidden && `display: none;`}
  flex: 4;
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
  margin-bottom: 24px;
  margin-right: 16px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContainerImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  background-size: cover;
  border-radius: 14px 14px 0 0;
  cursor: pointer;
  text-align: center;
`;

const IsSpanhol = styled(Text)`
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 10px 10px 0 0;
  padding: 5px 10px;
  margin-left: 10px;
  position: absolute;
  bottom: 0;
  right: 10px;
  display: inline;
`;

const NameRoom = styled(Text)`
  width: 100%;
  padding: 0 20px;
  text-transform: uppercase;
`;

const Badge = styled(Text)`
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 15px;
  padding: 5px 10px;
  margin-left: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline;
`;

const Content = styled.div`
  width: 100%;
  padding: 24px;
  line-height: 18px;
`;

const Title = styled(Text)`
  font-weight: 700;
`;

const Schedule = styled(Text)`
  margin: 4px 0;
`;

const Summary = styled(Text)`
  margin: 16px 0;
  line-height: 16px;
`;

const ContentTalks = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
`;

const TalkItem = styled.div`
  margin-right: 16px;
`;

const Speaker = styled(Text)`
  padding: 8px 0 0;
`;

const ButtonClosed = styled(Button)`
  background: ${({ theme }) => theme.palette.black.light};
  color: ${({ theme }) => theme.palette.black.main};
  cursor: not-allowed;
  border: 0;

  :hover {
    cursor: not-allowed;
  }
`;

export const CardRoom = ({ data, refreshList }) => {
  const [deting, setDeleting] = React.useState(null);
  const history = useHistory();

  const [, { loading }, deleteSchedule] = useService(
    'delete',
    `/app/schedule/${data._id}`,
    {},
    false
  );

  const handleOnRemove = async name => {
    Swal.fire({
      title: `Deseja mesmo remover da agenda "${name}"`,
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#37B6C4',
      cancelButtonColor: '#F51B0F',
      confirmButtonText: 'Sim Remover',
      cancelButtonText: 'Cancela',
      backdrop: `rgba(0,0,0,0.4)`,
    }).then(result => {
      if (!result.isConfirmed) {
        setDeleting(null);
      }
      if (result.isConfirmed) {
        deleteSchedule();
      }
    });

    refreshList();
  };

  return (
    <>
      {data._id !== deting && (
        <Container>
          <Loader direction="column" show={loading}>
            <ContainerImage>
              {data.isOnline && (
                <Badge type="span" color="white" bold size="10">
                  AO VIVO
                </Badge>
              )}

              <NameRoom type="h1" size="22" color="white" bold>
                {data.name}
              </NameRoom>

              {data.linkEs && (
                <IsSpanhol type="span" color="white" bold size="12">
                  Tradução espanhol
                </IsSpanhol>
              )}
            </ContainerImage>

            <Content>
              <Title type="h1" size="18" color="primary">
                {data.title}
              </Title>

              <Schedule type="p" size="14" semiBold color="primary">
                {dbToDate(data?.openAt, "dd 'de' MMMM HH'h'mm")}
              </Schedule>

              {/* {data?.talks.length === 1 ? (
                <>
                  <Speaker type="h3" size="16" color="primary" bold>
                    {data?.talks[0]?.speaker?.name}
                  </Speaker>

                  <Schedule type="p" size="14" color="primary">
                    {dbToDate(data?.talks[0]?.startAt, "dd 'de' MMMM HH'h'mm")}
                  </Schedule>
                </>
              ) : (
                <ContentTalks>
                  {data?.talks.map(talk => (
                    <TalkItem>
                      <Speaker type="h3" size="16" color="primary" bold>
                        {talk.speaker?.name} - {talk.title}
                      </Speaker>

                      <Schedule type="p" size="14" color="primary">
                        {dbToDate(talk.startAt, "dd 'de' MMMM HH'h'mm")}
                      </Schedule>
                    </TalkItem>
                  ))}
                </ContentTalks>
              )} */}

              <Summary size="16" type="p" color="black">
                {data.resumeDescription}
              </Summary>

              {!data.isOnline && data.isClosed && (
                <ButtonClosed onClick={() => history.push(`/app/room/${data._id}`)}>
                  Encerrado
                </ButtonClosed>
              )}

              {data.isOnline && !data.isClosed && (
                <Button color="primary" onClick={() => history.push(`/app/room/${data._id}`)}>
                  Assistir
                </Button>
              )}

              {!data.isClosed && !data.isOnline && (
                <Button
                  variant="outline"
                  color="primary"
                  onClick={() => {
                    setDeleting(data._id);
                    handleOnRemove(data.title);
                  }}>
                  REMOVER DA AGENDA
                </Button>
              )}
            </Content>
          </Loader>
        </Container>
      )}
    </>
  );
};
