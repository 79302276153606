import styled from 'styled-components';

import { Text } from '../Text/Text';

const Container = styled.div``;

const ResChats = styled.div`
  max-height: 300px;
  overflow: auto;
`;

const Resp = styled(Text)`
  margin-left: 8px;
`;

const ResItem = styled.div`
  margin: 10px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 4px;
`;

export const AdminLogRoom = () => {
  const dataChats = [
    { name: 'Diego Z Freitas', label: 'Como faz para gerar uma pagina legal assim?', likes: 30 },
    { name: 'Diego Z Freitas', label: 'Qual seria a relação entre modal e popUp?', likes: 12 },
    { name: 'Diego Z Freitas', label: 'Quando é o almoço?', likes: 64 },
    { name: 'Diego Z Freitas', label: 'Qual seria a relação entre modal e popUp?', likes: 12 },
    { name: 'Diego Z Freitas', label: 'Quando é o almoço?', likes: 64 },
    { name: 'Diego Z Freitas', label: 'Qual seria a relação entre modal e popUp?', likes: 12 },
    { name: 'Diego Z Freitas', label: 'Quando é o almoço?', likes: 64 },
  ];

  return (
    <Container>
      <Text type="p" color="primary" size="16" bold>
        Favorito:
        <Resp type="span" color="black" size="16">
          Sim
        </Resp>
      </Text>

      <Text type="p" color="primary" size="16" bold>
        Tempo assistido:
        <Resp type="span" color="black" size="16">
          30:00
        </Resp>
      </Text>

      <Text type="p" color="primary" size="16" bold>
        Completa:
        <Resp type="span" color="black" size="16">
          60%
        </Resp>
      </Text>

      <Text type="p" color="primary" size="16" bold>
        Mensagens do Chat:
        <ResChats>
          {dataChats.map((item, key) => (
            <ResItem key={key}>
              <Text type="p" color="black" size="16" bold>
                {item.label} <br />
                <Text type="span" size="14" bold>
                  {item.likes} likes
                </Text>
              </Text>
            </ResItem>
          ))}
        </ResChats>
      </Text>

      <Text type="p" color="primary" size="16" bold>
        Nota prova:
        <Resp type="span" color="black" size="16">
          8
        </Resp>
      </Text>
    </Container>
  );
};
