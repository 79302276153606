import React, { useContext } from 'react';
import styled from 'styled-components';
import { Loader, Button } from '@ederzadravec/jade-ui';

import { Text, Space } from 'components';
import { VideoPortuguese } from './VideoPortuguese';
import { VideoSpanish } from './VideoSpanish';
import { VideoEnglish } from './VideoEnglish';
import { useService } from 'service';

import { ContextLanguage } from 'contexts';

const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.white.main};
  border: 1px solid ${({ theme }) => theme.palette.black.light};
  border-radius: 14px;
`;

const Content = styled.div`
  padding: 32px;
`;

const ContentDescription = styled.div``;

const ContainerFullContent = styled.div`
  font-size: 16px;
`;

export const ContentVideo = ({ idRoom }) => {
  const [showContent, setShowContent] = React.useState(false);
  const [dataRoom, { loading }] = useService('get', `/app/room/${idRoom}`, {}, true, [idRoom]);
  const [translated, setTranslated] = React.useState(false);

  const room = dataRoom?.success?.room || [];

  if (!dataRoom) return '';

  const cxtLang = useContext(ContextLanguage);

  return (
    <Loader show={loading}>
      <Container>
        {cxtLang.lang === 'es' && <VideoEnglish link={room.link[cxtLang.lang]} />}

        {translated ? (
          <VideoEnglish link={room.link.es} />
        ) : (
          <>
            {cxtLang.lang === 'pt' && <VideoPortuguese link={room.link[cxtLang.lang]} />}

            {cxtLang.lang === 'en' && <VideoSpanish link={room.link[cxtLang.lang]} />}
          </>
        )}

        <Content>
          <Space mt="1" />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Text size="22" type="h1" color="primary" bold>
              {room.name[cxtLang.lang]}
            </Text>

            {/* <Button size="22" color="primary" onClick={() => setTranslated(!translated)}>
              {translated ? 'Áudio Original' : 'Traduzir'}
            </Button> */}
          </div>

          <Space mt="2" />

          {!showContent ? (
            <ContentDescription>
              <ContainerFullContent
                dangerouslySetInnerHTML={{ __html: room.resumeDescription[cxtLang.lang] }}
              />

              <Text
                type="link"
                bold
                size="16"
                color="primary"
                onClick={() => setShowContent(!showContent)}>
                {cxtLang.lang === 'pt' && '...Continuar Lendo '}
                {cxtLang.lang === 'en' && '...Continue Reading '}
                {cxtLang.lang === 'es' && '...Sigue Leyendo '}
              </Text>
            </ContentDescription>
          ) : (
            <ContentDescription>
              <ContainerFullContent
                dangerouslySetInnerHTML={{ __html: room.description[cxtLang.lang] }}
              />

              <Space mt={2} />

              <Text type="link" bold size="16" onClick={() => setShowContent(!showContent)}>
                {cxtLang.lang === 'pt' && '...Recolher'}
                {cxtLang.lang === 'en' && '...Decrease'}
                {cxtLang.lang === 'es' && '...Disminuir'}
              </Text>
            </ContentDescription>
          )}
        </Content>
      </Container>
    </Loader>
  );
};
