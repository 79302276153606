import { Switch, Route, matchPath } from 'react-router-dom';
import styled from 'styled-components';

import { Tabs, Text } from 'components';

import Talks from './talks/Talks';
import Quiz from './quiz/Quiz';
import Poll from './poll/Poll';
import Html from './html/Html';
import Question from './question/Question';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.black.light};
  padding-top: 24px;
  width: inherit;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export default ({ history, match }) => {
  const idRoom = match?.params?.room;
  const BASE_URL = `/admin/room/details/${idRoom}`;

  const [room, { loading }] = useService('get', `/room/${match.params.room}`);

  const tabConfig = [
    {
      label: 'Palestras',
      onClick: () => history.push(`${BASE_URL}/talk`),
      active: () => matchPath(history.location.pathname, { path: `${BASE_URL}/talk` }),
    },
    {
      label: 'Quiz',
      onClick: () => history.push(`${BASE_URL}/quiz`),
      active: () => matchPath(history.location.pathname, { path: `${BASE_URL}/quiz` }),
    },
    {
      label: 'Votações',
      onClick: () => history.push(`${BASE_URL}/poll`),
      active: () => matchPath(history.location.pathname, { path: `${BASE_URL}/poll` }),
    },
    {
      label: 'Html',
      onClick: () => history.push(`${BASE_URL}/html`),
      active: () => matchPath(history.location.pathname, { path: `${BASE_URL}/html` }),
    },
    {
      label: 'Tópicos',
      onClick: () => history.push(`${BASE_URL}/question-topic`),
      active: () => matchPath(history.location.pathname, { path: `${BASE_URL}/question-topic` }),
    },
  ];

  return (
    <Container>
      <Header>
        <Text type="h3" size="24" bold color="black">
          Criando itens para sala: {room?.success?.room?.name.pt}
        </Text>
      </Header>

      <Content>
        <Tabs config={tabConfig} />

        <Switch>
          <Route path={`${BASE_URL}/talk`} component={Talks} />
          <Route path={`${BASE_URL}/quiz`} component={Quiz} />
          <Route path={`${BASE_URL}/poll`} component={Poll} />
          <Route path={`${BASE_URL}/html`} component={Html} />
          <Route path={`${BASE_URL}/question-topic`} component={Question} />
        </Switch>
      </Content>
    </Container>
  );
};
