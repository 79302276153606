import React from 'react';
import styled from 'styled-components';
import { Button } from '@ederzadravec/jade-ui';

import { Box, Text, Space } from 'components';
import { Store } from 'contexts';
import { useService } from 'service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ContainerAward = styled(Box)`
  background: #dedede;
  width: 350px;
`;

const Link = styled.a`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.black.main};
`;

export default () => {
  const [showAward, setShowAward] = React.useState(false);
  const { user } = React.useContext(Store.Context);

  const [viewersData, { loading }, refreshViewers] = useService('get', '/viewer/detach');

  const [dataAward] = useService('get', `/award/get-award/${user.id}`, {}, true, [user.id]);
  const [, {}, setRescued] = useService('patch', `/award/set-rescued-award/${user.id}`, {}, false);

  const award = dataAward?.success?.award || [];

  console.log('euu', award);

  return (
    <Container>
      <Box>
        <Text type="h3" size="22" bold color="primary">
          VOUCHERS
        </Text>

        <Space mt={2} />

        <Text type="p" size="16">
          Olá!
          <br />
          <br />
          Se você recebeu um voucher no kit de boas vindas, esse é o local certo para o resgate ☺
          <br />
          Clique no botão resgate e veja qual prêmio você ganhou.
          <br />
          Esperamos que você aproveite muito seu presente!
        </Text>

        <Space mt={3} />

        <Button onClick={() => setShowAward(!showAward)}>Resgatar</Button>

        <Space mt={3} />
        {showAward && (
          <>
            {award?.award && !award?.rescued ? (
              <ContainerAward>
                <Text type="h3" size="22" bold color="primary">
                  Parabéns!
                </Text>

                <Space mt={2} />

                <Text type="p" size="16" color="black">
                  Você ganhou
                </Text>
                <Text type="p" size="16" color="black" bold>
                  {award?.award}
                </Text>

                <Space mt={1} />

                <Text type="p" size="16" color="black">
                  {award?.description}
                </Text>

                <Space mt={1} />

                {award?.link && (
                  <Link href={award?.link} target="_blank">
                    {award?.link}
                  </Link>
                )}
              </ContainerAward>
            ) : (
              <ContainerAward>
                <Text type="h3" size="22" bold color="alert">
                  NÃO FOI DESSA VEZ :/
                </Text>

                <Space mt={2} />

                <Text type="p" size="16" color="black">
                  Infelizmente, não existem vale-presentes atrelados a sua conta. Em caso de dúvidas
                  entre em contato com contato@hellomais.com.br.
                </Text>
              </ContainerAward>
            )}
          </>
        )}
      </Box>
      <br />
    </Container>
  );
};
