export const homeTexts = {
  es: {
    navBarTextHI: 'Hola ',
    navBarTextSearch: 'Buscar... ',

    menuUriHomePage: 'Comienzo',
    menuUriSchedulePage: 'Calendario',
    menuUriMyAgenda: 'Mi agenda',
    menuUriSpeakers: 'Altavoces',
    menuUriVouchers: 'Cupón',
    menuUriSettings: 'Ajustes',
    menuUriSponsors: 'Estandes',
    menuUriPosters: 'Póster',
    menuUriExit: 'Fuera',
    menuTextConfirmExit: '¿De verdad quieres irte?',
    menuConfirmButton: 'Sí',
    menuCancelButton: 'No',
  },
  en: {
    navBarTextHI: 'Hi ',
    navBarTextSearch: 'Search... ',

    menuUriHomePage: 'Start',
    menuUriSchedulePage: 'Schedule',
    menuUriMyAgenda: 'My Agenda',
    menuUriSpeakers: 'Speaker',
    menuUriVouchers: 'Vouchers',
    menuUriSponsors: 'Sponsors - 3D Experience',
    menuUriSettings: 'Settings',
    menuUriPosters: 'Poster',
    menuUriExit: 'Exit',
    menuTextConfirmExit: 'Do you really want to leave?',
    menuConfirmButton: 'Yes',
    menuCancelButton: 'No',
  },
  pt: {
    navBarTextHI: 'Olá ',
    navBarTextSearch: 'Pesquisar... ',

    menuUriHomePage: 'Início',
    menuUriSchedulePage: 'Agenda',
    menuUriMyAgenda: 'Minha Agenda',
    menuUriSpeakers: 'Palestrantes',
    menuUriVouchers: 'Vouchers',
    menuUriSponsors: 'Experiência 3D - Sponsors',
    menuUriSettings: 'Configurações',
    menuUriPosters: 'Pôster digital (e-Poster)',
    menuUriExit: 'Sair',
    menuTextConfirmExit: 'Deseja mesmo sair?',
    menuConfirmButton: 'Sim',
    menuCancelButton: 'Não',
  },
};
